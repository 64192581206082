@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: Black !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

// /* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    width: 2rem;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: $primary-color;
    top: 38%;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover, &:focus {
        outline: none;
        color: $primary-color;
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
        cursor: not-allowed;
    }
    &:before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 2.5rem;
        content: "\f007";
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
    }
}

.slick-prev {
    left: -0.3rem;
    [dir="rtl"] & {
        left: auto;
        right: -1.5rem;
    }
    &:before {
        content: $slick-prev-character;
        font-size: 1.8rem;
        color: $primary-color;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -0.3rem;
    [dir="rtl"] & {
        left: -1.5rem;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        font-size: 1.8rem;
        color: $primary-color;

        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 2.5rem;
}

.slick-dots {
    position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 5px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: $gray-500;
            display: block;
            height: 5px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                background: $primary-color;
            }
        }
        &.slick-active button {
            background: $primary-color;
        }
    }
}
.test {
    &::before {

        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 2.5rem;
        content: "\f007";
    }
}
