.main-timeline3 {
  overflow: hidden;
  position: relative;

  .timeline {
    position: relative;
    margin-top: -105px;
    margin-bottom: 50px;

    &:first-child {
      margin-top: 0;
    }
  }

  .timeline-profile{
    position: absolute;
    height: 250px;
    width: 25%;
    border: 10px solid #000;
    left: 5%;
    top: 20%;

    img{
      width: 100%;
      height: 100%;
    }
  }

  .timeline-icon, .year {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .timeline {
    &:after {
      content: "";
      display: block;
      width: 100%;
      clear: both;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      clear: both;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      // z-index: 2;
    }
  }

  .timeline-icon {
    width: 210px;
    height: 210px;
    border-radius: 50%;
    border: 25px solid transparent;
    border-top-color: #212529;
    border-right-color: #212529;
    z-index: 1;
    transform: rotate(45deg);
  }

  .year {
    display: block;
    width: 110px;
    height: 110px;
    line-height: 30px;
    padding-top: 10px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    font-size: 20px;
    font-weight: 700;
    color: #212529;
    text-align: center;
    transform: rotate(-45deg);
  }

  .timeline-content {
    width: 35%;
    float: right;
    background: #212529;
    padding: 30px 20px;
    margin: 50px 0;
    box-shadow: 0 10px 25px -10px rgba(72, 29, 99, 0.3);
    z-index: 1;
    position: relative;

    &:before {
      content: "";
      width: 20%;
      height: 15px;
      background: #212529;
      position: absolute;
      top: 50%;
      left: -15%;
      z-index: -1;
      transform: translateY(-50%);
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 10px;
  }

  .description {
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    margin: 0;
  }

  .timeline {
    &:nth-child(2n) {
      .timeline-icon {
        transform: rotate(-135deg);
        border-top-color: #28a745;
        border-right-color: #28a745;
      }
      .timeline-profile{
        border:10px solid #28a745;
        // right: 15%;
        left: 70%;
        top: 25%;
      }
      .year {
        transform: rotate(135deg);
        color: #28a745;
      }

      .timeline-content {
        float: left;

        &:before {
          left: auto;
          right: -15%;
          background: #28a745;
        }

        background: #28a745;
      }
    }

    &:nth-child(3n) {
      .timeline-icon {
        border-top-color: #ffc107;
        border-right-color: #ffc107;
      }
      .timeline-profile{
        border:10px solid #ffc107;
      }
      .year {
        color: #ffc107;
      }

      .timeline-content {
        background: #ffc107;

        &:before {
          background: #ffc107;
        }
      }
    }

    &:nth-child(4n) {
      .timeline-icon {
        border-top-color: #105572;
        border-right-color: #105572;
      }
      .timeline-profile{
        border:10px solid #105572;
        top: 30%;
      }
      .year {
        color: #105572;
      }

      .timeline-content {
        background: #105572;

        &:before {
          background: #105572;
        }
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .main-timeline3{
    .timeline{
      margin-top: 0px;
    }
    .timeline-content:before{
      left: -20%;
    }

    .timeline{
      .timeline-profile {
          height: 300px;
          top: 5%;
      }


      &:nth-child(2n){
         .timeline-content:before{
           right: -20%;
         }
         .timeline-profile{
           top: 10%;
         }
      }

      &:nth-child(4n) {
        .timeline-profile{
          top: 20%;
        }
      }

    }

  }
}

@media only screen and (max-width: 1280px) {
  .main-timeline3  {
    .timeline-profile{
      height: 230px;
    }
    .timeline-content{
      &:before{
        left: -10%;
      }
    }
    .timeline:nth-child(2n)  {
      .timeline-content{
        &:before{
          right: -10%;
        }
      }
    }
  }

}

@media only screen and (max-width: 1199px) {
  .main-timeline3 {
    .timeline {
      margin-top: -103px;
    }

    .timeline-content:before {
      left: -18%;
    }

    .timeline:nth-child(2n) .timeline-content:before {
      right: -18%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main-timeline3  {
    .timeline-profile{
      height: 230px;
      top: 25%;
    }

    .timeline-content{
      &:before{
        left: -10%;
      }
    }
    .timeline:nth-child(2n)  {
      .timeline-content{
        &:before{
          right: -10%;
        }
      }
      .timeline-profile{
        top: 30%;
      }
    }
  }

}

@media only screen and (max-width: 990px) {
  .main-timeline3 {
    .timeline {
      margin-top: -127px;
    }

    .timeline-content:before {
      left: -2%;
    }

    .timeline:nth-child(2n) .timeline-content:before {
      right: -2%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-timeline3 {
    .timeline {
      margin-top: 0;
      overflow: hidden;

      &:before, &:nth-child(2n):before {
        box-shadow: none;
      }
    }

   .timeline-profile {
        position: relative;
        height: 290px;
        width: 280px;
        border: 10px solid #000;
        left: 29vw;
    }

    .timeline-icon {
      margin-top: -30px;
      margin-bottom: 20px;
      position: relative;
      transform: rotate(135deg);
    }

    .timeline:nth-child(2n) {
      .timeline-icon {
        margin-top: -30px;
        margin-bottom: 20px;
        position: relative;
        transform: rotate(135deg);
      }

      .year {
        transform: rotate(-135deg);
      }
      .timeline-profile {
          left: 30%;
          top: 25%;
      }
    }

    .year {
      transform: rotate(-135deg);
    }

    .timeline-content, .timeline:nth-child(2n) .timeline-content {
      width: 100%;
      float: none;
      border-radius: 0 0 20px 20px;
      text-align: center;
      padding: 25px 20px;
      margin: 0 auto;
    }

    .timeline-content:before, .timeline:nth-child(2n) .timeline-content:before {
      width: 15px;
      height: 25px;
      position: absolute;
      top: -22px;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0);
    }
  }
}

@media only screen and (max-width: 568px) {
  .main-timeline3 {


   .timeline-profile {
        left: 5vw;
    }

    .timeline:nth-child(2n)  {

      .timeline-profile{
        left: 5vw;
      }
    }
  }
}
