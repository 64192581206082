nav {
  position: relative;
  margin: 0 auto;
}

ul{
  margin-left: 0;
  padding-left: 0;
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #head-mobile {
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu #head-mobile {
  display: none;
}
#cssmenu {
  // font-family: sans-serif;
}
#cssmenu > ul > li {
  float: left;
}
#cssmenu > ul > li > a {
  padding: 17px;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  // color: #333;
  font-weight: 700;
}
#cssmenu > ul > li:hover > a,
#cssmenu ul li.active a {
  color: rgba($white,1);
}
#cssmenu > ul > li:hover,
#cssmenu ul li.active:hover,
#cssmenu ul li.active,
#cssmenu ul li.has-sub.active:hover {
  background: $primary-color !important;
  color:rgba($white,1) !important;
  -webkit-transition: background 0.3s ease;
  -ms-transition: background 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease;

  a{
    color:rgba($white,1) !important;

    &:before,&:after{
      background-color:rgba($secondary-color,1) !important;
    }
  }
}
#cssmenu > ul > li.has-sub > a {
  padding-right: 30px;
}
#cssmenu > ul > li.has-sub > a:after {
  position: absolute;
  top: 22px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: rgba($white,0.3);
  content: "";
}
#cssmenu > ul > li.has-sub > a:before {
  position: absolute;
  top: 19px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: rgba($white,0.3);
  content: "";
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#cssmenu > ul > li.has-sub:hover > a:before {
  top: 23px;
  height: 0;
}
#cssmenu ul ul {
  position: absolute;
  // top:0;
  margin-top:1px;
  
  left: -9999px;
}

////// displaying of the dropdown
#cssmenu ul ul li {
 // height: 0;
 
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  background: $primary-color-dark;
  transition: all 0.25s ease;
  position: relative;
  left:0px;
  opacity: 1;
  top:0px; ///////////////////////// spanner

  @include media-breakpoint-up(lg){
    left:-50px;
    opacity: 0;
    padding:5px;
    top:5px; ///////////////////////// spanner
  }

  &:hover{
    background: $primary-color;
    &>a{
      color: rgba($white,1) !important;
    }
  }
}



#cssmenu li:hover > ul > li {
  // height: 35px;
  left:0px;
  opacity: 1;
 }

 #cssmenu > ul > li {
  float: none;
  border-right:1px solid rgba($white,0.3);
  &:last-child{
    border-right:unset
  }
}

 ////////////////////////////////////////


#cssmenu ul ul li:hover {
}
#cssmenu li:hover > ul {
  left: auto;
}

#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}
#cssmenu ul ul li a {
  // border-bottom: 1px solid rgba(150, 150, 150, 0.15);
  padding: 11px 15px;
  width: 250px;
  font-size: 14px;
  text-decoration: none;
  color: #ddd;
  font-weight: 400;
}

#cssmenu ul ul li {
  @media screen and (min-width: 991.98px){
     border-bottom: 1px solid rgba($white, 0.15);
  }
 
}
#cssmenu ul li ul li:last-child{
  @media screen and (min-width: 991.98px){
     &:after{
       content:"";
       display: block;
       width:100%;
       height:100%;
       position: absolute;
       bottom:-5px;left:0;
       height:2px;
       background-color: rgba($secondary-color, 1);
     }
    //  border-bottom: 3px solid rgba($secondary-color, 1);
  }
 
}

#cssmenu ul ul li:last-child > a,
#cssmenu ul ul li.last-item > a {
  border-bottom: 0;
}
#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #fff;
}
#cssmenu ul ul li.has-sub > a:after {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #ddd !important;
  content: "";
}
#cssmenu ul ul li.has-sub > a:before {
  position: absolute;
  top: 13px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #ddd !important;
  content: "";
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#cssmenu ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}
#cssmenu ul ul li.has-sub:hover ,
#cssmenu ul li.has-sub ul li.has-sub ul li:hover {
 // background: $primary-color-dark;
    transition: all 0.25s ease;
}
#cssmenu ul ul ul li.active a {
  border-left: 1px solid #333;
}
#cssmenu > ul > li.has-sub > ul > li.active > a,
#cssmenu > ul ul > li.has-sub > ul > li.active > a {
 // border-top: 1px solid #333;
 transition: all 0.25s ease;
}

@media screen and (max-width: 991.98px) {
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 46px;
    text-align: center;
    padding: 10px 0 0 0;
    float: none;
  }
  .logo2 {
    display: none;
  }
  nav {
    width: 100%;
    transition:all 0.3s;
  }
  #cssmenu {
    width: 100%;
    transition:all 0.3s;
  }
  #cssmenu ul {
    width: 100%;
    display: none;
  }
  #cssmenu ul li {
    width: 100%;
   // border-top: 1px solid #444;
  }
  #cssmenu ul li:hover {
    //background: #363636;
  }
  #cssmenu ul ul li,
  #cssmenu li:hover > ul > li {
    height: auto;
  }
  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
    border-bottom: 0;
  }
  #cssmenu > ul > li {
    float: none;
    border-right:unset;
  
  }
  #cssmenu ul ul li a {
    padding-left: 25px;
  }
  #cssmenu ul ul li {
    background: none  !important;
    transition: all 0.3s ease;
    border-top:1px solid rgba($secondary-color,0.3);
  }
  #cssmenu ul ul li:hover {
   // background:$primary-color !important;
  }
  #cssmenu ul ul ul li a {
    padding-left: 35px;
  }
  #cssmenu ul ul li a {
    color: #ddd;
    background: none;
  }
  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li.active > a {
    color: #fff;
  }
  #cssmenu ul ul,
  #cssmenu ul ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
     
  }
  #cssmenu > ul > li.has-sub > a:after,
  #cssmenu > ul > li.has-sub > a:before,
  #cssmenu ul ul > li.has-sub > a:after,
  #cssmenu ul ul > li.has-sub > a:before {
    display: none;
  }
  #cssmenu #head-mobile {
    display: block;
    padding: 23px;
    color: #ddd;
    font-size: 14px;
    font-weight: 700;
  }
  .menu-button {
    width: 55px;
    height: 46px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 12399994;
  }
  // .menu-button:after {
  //   position: absolute;
  //   top: 22px;
  //   right: 20px;
  //   display: block;
  //   height: 4px;
  //   width: 20px;
  //   border-top: 2px solid #666;
  //   border-bottom: 2px solid #666;
  //   content: "";
  // }
  // .menu-button:before {
  //   -webkit-transition: all 0.3s ease;
  //   -ms-transition: all 0.3s ease;
  //   transition: all 0.3s ease;
  //   position: absolute;
  //   top: 16px;
  //   right: 20px;
  //   display: block;
  //   height: 2px;
  //   width: 20px;
  //   background: #666;
  //   content: "";
  // }
  // .menu-button.menu-opened:after {
  //   -webkit-transition: all 0.3s ease;
  //   -ms-transition: all 0.3s ease;
  //   transition: all 0.3s ease;
  //   top: 23px;
  //   border: 0;
  //   height: 2px;
  //   width: 19px;
  //   background:#666;
  //   -webkit-transform: rotate(45deg);
  //   -moz-transform: rotate(45deg);
  //   -ms-transform: rotate(45deg);
  //   -o-transform: rotate(45deg);
  //   transform: rotate(45deg);
  // }
  // .menu-button.menu-opened:before {
  //   top: 23px;
  //   background: #666;
  //   width: 19px;
  //   -webkit-transform: rotate(-45deg);
  //   -moz-transform: rotate(-45deg);
  //   -ms-transform: rotate(-45deg);
  //   -o-transform: rotate(-45deg);
  //   transform: rotate(-45deg);
  // }
  #cssmenu .submenu-menu-button {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid #444;
    height: 46px;
    width: 46px;
    cursor: pointer;
  }
  #cssmenu .submenu-menu-button.submenu-opened {
    background: #262626;
  }
  #cssmenu ul ul .submenu-menu-button {
    height: 34px;
    width: 34px;
  }
  #cssmenu .submenu-menu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #ddd;
    content: "";
  }
  #cssmenu ul ul .submenu-menu-button:after {
    top: 15px;
    right: 13px;
  }
  #cssmenu .submenu-menu-button.submenu-opened:after {
    background: #fff;
  }
  #cssmenu .submenu-menu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #ddd;
    content: "";
  }
  #cssmenu ul ul .submenu-menu-button:before {
    top: 12px;
    right: 16px;
  }
  #cssmenu .submenu-menu-button.submenu-opened:before {
    display: none;
  }
  #cssmenu ul ul ul li.active a {
    border-left: none;
  }
  #cssmenu > ul > li.has-sub > ul > li.active > a,
  #cssmenu > ul ul > li.has-sub > ul > li.active > a {
    border-top: none;
  }
}
