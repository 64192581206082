.meeting-cover{
  position: relative;

  .meeting-slider{
    padding: 0;
    margin: 0;
    width: 100%;
    display: block;
    position: relative;
    list-style: none;
    word-break: break-all;
    .slider-content-center{
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
    }
    .slider-content-left{
      max-width: 800px;
      text-align: left;
    }
    .cover_item{
      width: 100%;
      position: relative;
       height: 12vh;
      // min-height: 545px;
      //padding: 120px 0;
      background-position: center center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      .slider_content{
        display: table;
        background-color: rgba(0, 0, 0, 0.2);
        height: 100%;
        min-width: 60%;
        position: relative;
        top: 80px;
        bottom: 0;
        color: #ffffff;

        .slider-content-inner{
          display: table-cell;
          vertical-align: middle;
          .cover-title{
            margin-top: 15px;
            margin-bottom: 0;
            opacity: 0;
            font-weight: 500;
            color: #ffffff;
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
            -webkit-transition: all 0.4s 600ms ease-in-out;
            transition: all 0.4s 600ms ease-in-out;
          }
          .cover-xl-text{
            opacity: 0;
            font-size: 90px;
            font-weight: 900;
            line-height: 1.1;
            color: $primary-color;
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
            -webkit-transition: all 0.4s 600ms ease-in-out;
            transition: all 0.4s 600ms ease-in-out;
          }
          .cover-date{
            font-size: 18px;
            color:#ffffff;
            margin-bottom: 25px;
            text-transform: uppercase;
            opacity: 0;
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
            -webkit-transition: all 0.4s 900ms ease-in-out;
            transition: all 0.4s 900ms ease-in-out;
          }
          a{
            opacity: 0;
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
            -webkit-transition:  transform  0.4s 1200ms ease-in-out, opacity 0.4s 1200ms ease-in-out;
            transition:  transform  0.4s 1200ms ease-in-out, opacity 0.4s 1200ms ease-in-out;
          }
        }
      }
    }
    .active .slider_content .slider-content-inner *{
      .cover-title{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition: all 0.4s 300ms ease-in-out;
        transition: all 0.4s 300ms ease-in-out;
      }
      .cover-xl-text{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition: all 0.4s 600ms ease-in-out;
        transition: all 0.4s 600ms ease-in-out;
      }
      .cover-date{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition: all 0.4s 900ms ease-in-out;
        transition: all 0.4s 900ms ease-in-out;
      }
      a{
        opacity: 1;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition: all 0.4s 1200ms ease-in-out, opacity 0.4s 1200ms ease-in-out, color 0.4s ease-in-out, border 0.4s  ease-in-out, background-color 0.4s  ease-in-out;
        transition:  all 0.4s 1200ms ease-in-out, opacity 0.4s 1200ms ease-in-out, color 0.4s  ease-in-out, border 0.4s  ease-in-out, background-color 0.4s  ease-in-out;

      }
    }
    .owl-dots{
      bottom: 25px;
      text-align: center;
      position: absolute;
      width: 100%;
      .owl-dot span {
        width: 15px;
        height: 2px;
        margin: 5px;
        background: rgba(255, 255, 255, 0.5);
        display: block;
        -webkit-backface-visibility: visible;
        // @include single-transition(all,0.4s, ease-in-out);
      }
      .owl-dot.active span{
        background: $white;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        // @include single-transition(all,0.4s, ease-in-out);
      }
    }
  }

  .meeting-nav{
    position: relative;
    bottom: 0;
    z-index: 999;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    top: -40px;
    .meeting-dots{
      padding: 15px 90px;
      margin: 0 auto;
      text-align: center;
      float: none;
      width: auto;
      display: inline-block;
      list-style: none;
      position: relative;
      background-color: #ffffff;
      top: -14px;
      border-top-left-radius: 51px;
      border-top-right-radius: 51px;
      li{
        display: inline-block;
        border: solid 2px transparent;
        border-radius: 14px;
        margin: 0;
        width: 30px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        padding: 5px 0;
        font-weight: 500;
        color: #000000;
      }
      li.active{
        border: solid 2px $primary-color;
      }
      &:before{
        content: " ";
        display: block;
        z-index: 99999;
        position: absolute;
        left: -30px;
        bottom: 0;
        top: 21px;
        border-top-left-radius: 0;
        width: 0;
        height: 0;
        border-bottom: 40px solid white;
        border-left: 40px solid transparent;
      }
      &:after{
        content: " ";
        display: block;
        z-index: 99999;
        position: absolute;
        right: -30px;
        bottom: 0;
        top: 21px;
        border-top-left-radius: 0;
        width: 0;
        height: 0;
        border-bottom: 40px solid #ffffff;
        border-right: 40px solid transparent;
      }
    }
  }

  .inner_cover {
    min-height: 400px;
    position: relative;
    background-position: center center;
    background-size: cover;
    .overlay_dark {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      background-color: rgba(31, 31, 32, 0.6);
    }
    .inner_cover_content {
      padding: 170px 0;
      h3 {
        text-transform: capitalize;
        color: $white;
        font-size: 48px;
        font-weight: 500;
      }
    }
  }

}
.modal-open{
  .modal{
    background: rgba(255,255, 255, 0.4);
    .partner{
      img{
        width: 100px;
      }
    }
  }
}
.ega-meeting {
  margin-bottom: 100px;
  .overlay {
      width: 200px;
      height: 200px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: rgba(0, 0, 0, 0.05);
      background: $primary-color;
      margin-top: -30px;
      opacity: 1;

      img{
        height: 90%;
        margin-top: 5%;
        width: 90%;
        margin-left: 5%;
      }
    }
  .overlay-left {
      margin-left: -25px;
    }
  .overlay-right {
      left: 255px;
      // z-index: 1;
    }

    img{
      display: block;
      width: 100%;
      position: relative;
      z-index: 2;
      align-self: stretch;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-box-shadow: -20px 20px 69px -27px rgba(0, 0, 0, 0.33);
      -moz-box-shadow: -20px 20px 69px -27px rgba(0, 0, 0, 0.33);
      box-shadow: -20px 20px 69px -27px rgba(0, 0, 0, 0.33);
    }

    .meeting-title{
      font-size: 34px;
      text-transform: capitalize;
    }
    .video-testimonial-block{
      height: 120px;
    }
}

.combined-images{
  min-height: 450px;
  position: relative;
  z-index: 1;

  .combine-img-back {
    background-image: linear-gradient(90deg,#ffc107,transparent);
    left: 17.5%;
    top: 20.5%;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

  }
  .combine-img-second {
    right: 15%;
    left: auto;
    top: 1%;
  }

  .combine-img-foreground {
      top: auto;
      bottom: 15%;
      left: 0;
  }

  .combine-img {
      position: absolute;
      display: block;
      width: 50%;
      padding-bottom: 50.6%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      -webkit-box-shadow: 0 0.1875rem 1rem 0 rgba(0,0,0,.19);
      box-shadow: 0 0.1875rem 1rem 0 rgba(0,0,0,.19);
  }
}

.white-shadow-left:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3.125em;
    background-image: -webkit-gradient(linear,left top,right top,from(#fff),to(hsla(0,0%,100%,0)));
    background-image: -webkit-linear-gradient(left,#fff,hsla(0,0%,100%,0));
    background-image: -o-linear-gradient(left,#fff,hsla(0,0%,100%,0));
    background-image: linear-gradient(90deg,#fff,hsla(0,0%,100%,0));
    pointer-events: none;
    z-index: 1;
}
.white-shadow-right:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3.125em;
    background-image: -webkit-gradient(linear,left top,right top,from(#fff),to(hsla(0,0%,100%,0)));
    background-image: -webkit-linear-gradient(left,#fff,hsla(0,0%,100%,0));
    background-image: -o-linear-gradient(left,#fff,hsla(0,0%,100%,0));
    background-image: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    pointer-events: none;
    z-index: 1;
}

.meeting-cover .meeting-slider .cover_item {
    // min-height: 580px;
  }
.meeting-cover .meeting-slider .cover_item .slider_content{
  background-color: rgba($primary-color,0.5);
}

.owl-theme .owl-nav {
  position: absolute;
  margin-top: -25%;
  width: 100%;
}
.owl-theme .owl-nav .owl-prev{
  position: absolute;
  left: 1.5%;
}
.owl-theme .owl-nav .owl-next{
  position: absolute;
  right: 1.5%;
}
.owl-theme .owl-nav i{
  color:#34a9de;
}
.owl-theme .owl-nav [class*=owl-] {
  background: transparent !important;
}

@media(max-width:767px){

  .meeting-slider{
    .cover_item{
      .slider_content{
        .slider-content-inner{
          .cover-title{
            font-size: 18px;
          }
          .cover-xl-text{
            font-size: 46px;
          }
          .cover-date{
            font-size: 14px;
          }
        }
      }
    }
  }
  .ega-meeting {
    margin-bottom: 10px;
  }

  .combined-images .combine-img-foreground {

    left: 35px;
  }
}

@media (min-width: 1400px){
  .combine-img-foreground {
      left: -5.1%;
  }
}

@media (max-width:576px){
  .meeting-cover {
    .meeting-slider {
      .cover_item {
        .slider_content {
          left: -8%;
          .slider-content-inner {
            .cover-xl-text{
              font-size: 38px;
            }
          }
        }
      }
    }
  }

  .ega-meeting {
    .overlay {
      display: none;
    }
     .video-testimonial-block {
       height: 150px;
     }
  }
}
