
div.contact{
    //Google Map
    .google-map {
        

        #map {
            height: 100%;
            min-height: 300px;
            h6 {
                margin-bottom: 1px;
            }
        }
    }

    form{
        display: block;
        position: relative;

        legend {
            margin-bottom: 3rem;
            background: $primary-color;
            padding: 0.5rem 1rem;
            font-size: 1em;
            color: #fff;
            &+ .row {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        label{
            display: block;
            position: relative;
            top: -20px;
            left: 0px;
            z-index: 1;
            transition: all 0.3s ease-out;
        }


        input, textarea{
            display: block;
            position: relative;
            background: none;
            border: none;
            border-bottom: 1px solid rgb(162, 162, 162);
            width: 100%;
            z-index: 2;
        }

        input:focus, input:valid, textarea:focus {
            outline: none;
            border-bottom: 1px solid #00aced;
        }


        input:focus + label, input:valid + label, textarea:focus + label, textarea:valid + label {
            top: -40px;
            font-size: 1rem;
            color: $secondary-color;
        }

        button.btn {
            margin-right: 1rem;
            margin-top: 1rem;
            border-radius: 0;
        }
    }
}

.contact-form{
    .form-group{
        margin:3em auto;
        float: none;
    }

    label{
        margin-bottom: 0;
    }

    input{
        border-color:$primary-color !important;
    }
}

.separtator-line{
    height: 5px;
    background-color: $primary-color;
}

.map{
    border-top:5px solid $primary-color;
    outline:1px solid rgba($color: white, $alpha: 1);
}


.spaced-list-item {
    margin-bottom:2em;
}
