/* START SELECT3.css */
.searchSelect3 {
  position: relative;
  color: #555;
  width: 100%;
  /*font-size: 16px;*/
}

.searchSelect3_Input {
  height: 90px;
  background: #fcfcfc;
  border: 1px solid #aaa;
  width: 100%;
  outline: unset;
  padding:0.5em;
  max-width: 100%;
  padding-right: 1em;
  
  &:focus{
    border: 1px solid rgba($secondary-color, 1);
  }
  /*font: 200 16px/1.5 Helvetica, Verdana, sans-serif;*/
}

.searchSelect3_Caret_Down {
  position: absolute;
  top: 10px;
  left:unset;
  right: 0.5em;
  cursor: pointer;
  color:#555;
  display: none;

}

.searchSelect3_Times {
  position: absolute;
  top: 1em;
  left:unset;
  right: 0.5em;
  cursor: pointer;
  display: none;
  color: $secondary-color;
}

.searchSelect3_List {
  list-style-type: none;
  margin: 0;
  
  overflow: auto;
  overflow-x: hidden;
  height: 230px;
  width: 100%;
  position: absolute;
  // -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  // -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  // box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
}

.faq-ans{
  height: 170px;
  width: 100%;
}

.searchSelect3_List li {
  padding-right: 0.3em;
  transition: all 0.3s;
  list-style-type: none;
  background: #ffffff;
  padding-top:0;
  padding:4px 0px;
  color: #454545;
  /*font: 200 16px/1.5 Helvetica, Verdana, sans-serif;*/
  border-bottom: 1px solid #ccc;
}

.searchSelect3_List li:hover {
  cursor: pointer;
  background: #efefef;
  color: rgba($secondary-color, 1);
}


.searchSelect3_List li label {
  // padding: 0.5em;
  cursor: pointer;
}

.searchSelect3_List li:hover label {
  // color: #ffffff;
}

.searchSelect3_Input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.searchSelect3_Input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.searchSelect3_Input::-webkit-search-decoration,
.searchSelect3_Input::-webkit-search-cancel-button,
.searchSelect3_Input::-webkit-search-results-button,
.searchSelect3_Input::-webkit-search-results-decoration {
  display: none;
}
/* END SELECT3.css */

#wrapper{

}