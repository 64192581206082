.ega-footer {
	font-size: 14px;
	// border-top: 3px solid $primary-color;
	//background: $primary-color;
	padding: 0;

	p{
		color: rgba($white, .8);
		//color: $primary-text-color;
	}

	a {
		color: rgba($white, 0.8);
		transition:all 0.3s;
		transition-timing-function:ease;

		&:hover {
			color: rgba($secondary-color-dark,1);
		}
	}

	.footer-content {
	  padding: 30px 0 10px;
	  color: rgba($white,0.8);
	  font: 400 15px / 24px 'Roboto';

		.footer-heading {
			color: rgba($white, .9);
			font-weight: 500;
			//font: 400 21px / 25px 'Roboto-Bold';
			display: inline-block;
			margin-top: 0
		}

		.footer-links {
		    border-top: 1px solid #e0b772;
		    display: block;
		    clear: both;
		    padding-top: 18px;

				.list a {
				    text-transform: capitalize;
				    padding-right: 15px
				}
		}

		.footer-media a {
		    font: 400 19px / 25px 'FontAwesome';
		    padding: 0 10px;

			.icon{
				color: rgba($white, .9);
			}
		}
	}

	.credit {
		background-color: $primary-color-dark;
		padding: 15px 0px;
		color: $primary-text-color;
		font: 400 15px / 30px;
	}

}

@media(max-width:992px){

}
