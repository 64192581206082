header{
  //min-height: 150px;
  // background-color: $primary-color;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background .5s ease-in;
  position: relative;
  z-index: 2;

  a {
    color: $accent-color;
    vertical-align: center;
   
    &:hover {
        color:$primary-color;
        font-weight: 600;
        transition: all .3s ease;
    }

  }

  .top-list, .top_nav {
    font-size: .8em;
    margin: 0;
    height: 35px;

    li {
      float: right;
      text-align: center;

      &:first-child {
        margin-right: 0px;
      }

      &:not(:last-child) {
         margin: 0 !important;
      }    

      .search-form {
            padding: 0.2rem;

            .form-inline {
                flex-flow: nowrap;

                input.form-control {
                    font-size: .9rem;
                    line-height: 1.2;
                    height: 26px;
                    width: 10rem;
                    border-radius: 30px;
                    background-color: white;
                    border: 0.5ps solid $primary-color;
                    color: #666;
                    padding: 0.2rem 0.75rem;

                    &::placeholder {
                        color: #666;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder {
                        color: #666;
                    }

                    &::-ms-input-placeholder {
                        color: #666;
                    }
                }
            }

            .search-icon{
              margin-left: -45px;
              border:none;
              background: transparent;

              i{
                color: $primary-color;
              }
              &:focus{
                color: $secondary-color;
              }
            }
        }
      }
  }

  .top_nav{
    .top_nav {
        overflow: hidden;
    }

    .top_menu {
        height: 33px;
        margin: 0px auto;
        background-color: #333;
        position: relative;
        min-width: 37%;

        &:before{
          content: "";
          width: 0;
          height: 0;
          border-right: 33px solid #333;
          border-bottom: 33px solid transparent;
          float: left;
          left: -33px;
          position: absolute;
        }
        &:after{
          content: "";
          width: 0;
          height: 0;
          top: 0;
          border-top: 33px solid #333;
          border-right: 25px solid transparent;
          position: absolute;
          right: -24px;
        }
    }

  }

  .top-middle {
   // background: rgba($white,1);
     // border-top: 2px solid $primary-color;
      .emblem,
      .logo {
          width: 12rem;
      }

      .title {
          @include media-breakpoint-down(sm){
            font-size: 1em ;
          } 
      }

      .logo{
        @include media-breakpoint-down(sm){
         height:110px; width:auto
        }
      }

      h1 {
          //text-shadow: 0 1px 1px $primary-color;
          //text-shadow: 3px 3px 2px rgba(255, 255, 255, 0.50);
      }

      .text-dec {
          color: white;
          font-size: 2.8rem;
      }
      .client-logo{
        img{
          width: 60% !important;
        }
      }
  }

 

  .top-bottom {
    .navbar {
       // background: $primary-color !important;
        
        //border-bottom: 1px solid $primary-color;

        .navbar-nav > li {
          padding: .2em .4em;
          border-top: 5px solid $primary-color;

          &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }


        }

        .dropdown-menu {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: -1px;
            min-width: 10rem;

            .dropdown-item {
                font-size: .9em;
                padding: 0.6rem 1.5rem;
               // text-transform: capitalize !important;
              }
        }

        .dropdown-toggle::after {
            vertical-align: baseline !important;
            padding-left: -1em;
            margin-left: 0.59rem;
        }

        .dropdown-submenu {
            position: relative;
        }

        .dropdown-submenu>.dropdown-menu {
            top: 0;
            left: 100%;
            margin-top: -1px;
            margin-left: -1px;
            -webkit-border-radius: 0 6px 6px 6px;
            -moz-border-radius: 0 6px 6px;
            border-radius: 0 6px 6px 6px;
        }

        .dropdown-submenu:hover>.dropdown-menu {
            display: block;
        }

        .dropdown-submenu>a:after {
            display: block;
            content: " ";
            float: right;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 0 5px 5px;
            border-left-color: #ccc;
            margin-top: 5px;
            margin-right: -10px;
        }

        .dropdown-submenu:hover>a:after {
            border-left-color: #fff;
        }

        .dropdown-submenu.pull-left {
            float: none;
        }

        .dropdown-submenu.pull-left>.dropdown-menu {
            left: -100%;
            margin-left: 10px;
            -webkit-border-radius: 6px 0 6px 6px;
            -moz-border-radius: 6px 0 6px 6px;
            border-radius: 6px 0 6px 6px;
        }

        .search {
            button.btn-search {
                background: none;
                outline: none;
                border: none;
                cursor: pointer;

                i.fa {
                    font-size: .9rem;
                    color: white;
                }
            }

            .search-form {
                padding: 0.2rem;

                .form-inline {
                    flex-flow: nowrap;

                    input.form-control {
                        font-size: .9rem;
                        line-height: 1.2;
                        width: 15rem;
                        border-radius: 30px;
                        background-color: white;
                        border: none;
                        color: #666;
                        padding: 0.2rem 0.75rem;

                        &::placeholder {
                            color: #666;
                            opacity: 1;
                        }

                        &:-ms-input-placeholder {
                            color: #666;
                        }

                        &::-ms-input-placeholder {
                            color: #666;
                        }
                    }
                }
            }
        }
    }
  }

  // add bottom-border for fixed headers
  .fixed-top{
    border-bottom: 2px solid rgba($primary-color,0.7);

    @include media-breakpoint-down(sm) {
      position: initial;
      border-bottom: none;
      z-index: initial;
    }
  }
}

header{
  // .bg-header-trans:before{
  &.bg-header-trans:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
   // background: rgba($white, 0.7);

    // background: linear-gradient(
    //   to top,
    //   rgba($primary-color, 1),
    //   rgba($dark-color, 0.7)
    // ) !important;

    @include media-breakpoint-up(lg) {
      // background: rgba($white, 0.7); 
    }
  }

}







.main-navigation{
  a{
    color:rgba($white,1) !important;
    font-weight: bold;

    &:hover{ color:rgba($accent-color,0.9)!important;}
  }

  li{
      // text-transform: initial;
      background-color: rgba($secondary-color,0);
      transition: background-color 0.3s;

      &:hover{
        background-color: rgba($secondary-color, 1);
      }

  }

  li li a{
    color:rgba($primary-color-dark,1) !important;
    font-weight: bold !important;
  }

  .capitalize-parent-items > li { 
    text-transform: uppercase; 
    //a{font-size: 0.9em !important;}
    @include media-breakpoint-up(lg){padding:5px 0px;}
  }

}

.border-top-secondary{
  border-top:1px solid rgba($secondary-color,0.3) !important;
}


.medium-text{
  font-size: 14px;
}


