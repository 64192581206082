  html, body {
    position: relative;
    //background-color: darken(rgb(237, 238, 240),5%);
    background-color: #f8f9fa;
    //font-family: 'Roboto', 'san-serif';
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    text-decoration: none;
    color: rgba(0,0,0,0.9);
    line-height: 1.6;
    font-family: 'Segoe UI', Segoe, 'Segoe WP', Tahoma, Verdana, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: $secondary-text-color;

    &.text-white{
      &:hover{
        // color: $secondary-color !important;
      }
    }

    &.text-dark{
      p{
        color: #333;
      }
      &:hover{
        color: $secondary-color;

        p{
          color: $secondary-color;
        }
      }
    }

    &:hover,
    &:focus {
      color: $secondary-color;
        text-decoration: none !important;
        outline: none !important;
      transition: all .5s ease-in;
    }
}

p {
    word-wrap: break-word;
}

.bg-cover{
  background-repeat: no-repeat;
  background-size: cover;
}
.cover-title{
  position: absolute;
  top0: 0;
  top: 0;
  margin-top: 2em !important;
  padding-left: 15px;
  color: white;
  font-weight: 900;
  font-size: 3em;

}
.zoom-card{
  &:hover{
    &.card{
      cursor: pointer;
      z-index: 2;
      box-shadow: 0 0 15px $accent-color;
      transition: all .2s ease-in-out;
      transform: scale(1.2);
    }
  }
}
.container{
  max-width: 1240px;

  &.max-1920{
    //max-width: 1920px;
    max-width: 1920px;
  }
}

.b-15{
  border: 15px solid #dddddd;
}
  .h-200{
    height: 200px;
  }
.font-8{
  font-size: 8px;
}

.font-9{
  font-size: 9px;
}

.font-10{
  font-size: 10px;
}
.font-11{
  font-size: 11px;
}
.font-12{
  font-size: 12px;
}
.font-13{
  font-size: 13px;
}
.font-14{
  font-size: 14px;
}
.font-15{
  font-size: 15px;
}
.font-16{
  font-size: 16px;
}
.font-17{
  font-size: 17px;
}

.h-min-20{
  min-height: 20px;
}
.h-min-30{
  min-height: 30px;
}
.h-min-40{
  min-height: 40px;
}
.h-min-45{
  min-height: 45px;
}
.h-min-40{
  min-height: 40px;
}

.h-169{
  height: 169px;
}


.mt-neg-40{
  margin-top: -40px;
}

.mt-neg-50{
  margin-top: -50px;
}

.mt-neg-60{
  margin-top: -60px;
}

.mt-neg-100{
  margin-top: -100px;
}


.h-10 {
  height: 10px; }

.h-20 {
  height: 20px; }

.h-30 {
  height: 30px; }

.h-40 { 
  height: 40px; }

.h-50 {
  height: 50px !important;}

.h-60 {
  height: 60px; }

.h-70 {
  height: 70px; }

.h-80 {
  height: 80px; }

.h-90 {
  height: 90px; }

.h-100 {
  height: 100px; }

.h-110 {
  height: 110px; }

.h-120 {
  height: 120px; }

.h-130 {
  height: 130px; }

.h-140 {
  height: 140px; }

.h-150 {
  height: 150px; }

.h-160 {
  height: 160px; }

.h-170 {
  height: 170px; }

.h-180 {
  height: 180px; }

.h-190 {
  height: 190px; }

.h-200 {
  height: 200px; }

.h-210 {
  height: 210px; }

.h-220 {
  height: 220px; }

.h-230 {
  height: 230px; }

.h-240 {
  height: 240px; }

.h-250 {
  height: 250px; }

.h-260 {
  height: 260px; }

.h-270 {
  height: 270px; }

.h-280 {
  height: 280px; }

.h-290 {
  height: 290px; }

.h-300 {
  height: 300px; }

.h-310 {
  height: 310px; }

.h-320 {
  height: 320px; }

.h-330 {
  height: 330px; }

.h-340 {
  height: 340px; }

.h-350 {
  height: 350px; }

.h-360 {
  height: 360px; }

.h-370 {
  height: 370px; }

.h-380 {
  height: 380px; }

.h-390 {
  height: 390px; }

.h-400 {
  height: 400px; }

.h-410 {
  height: 410px; }

.h-420 {
  height: 420px; }

.h-430 {
  height: 430px; }

.h-440 {
  height: 440px; }

.h-450 {
  height: 450px; }

.h-460 {
  height: 460px; }

.h-470 {
  height: 470px; }

.h-480 {
  height: 480px; }

.h-490 {
  height: 490px; }

.h-500 {
  height: 500px;
}

.w-200-px{
  width: 200px;
}

.text-primary{
  color: $primary-color !important;
}
.text-secondary{
  color: $secondary-color !important;
}
.text-gray{
  color: gray;
}
.modal{
  .modal-lg{
    max-width: 80%;
  }
  .modal-content{
    border: none;

    .modal-header{
      border-bottom: none;
    }
  }
}
.modal-backdrop{
 background: $primary-color;
}
  .play-button {
    position: absolute;
    z-index: 10;
    top: 40%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    background: rgba($primary-color,.8);
    border-radius: 15%;
    padding: 17px 15px 17px 20px;
    transition: all .6s ease-in-out;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

    span {
      display: block;
      position: relative;
      z-index: 3;
      border-left: 10px solid $secondary-color;
      border-top: 7px solid transparent;
      position: relative;
      left: -1px;
      border-bottom: 7px solid transparent;
    }
  }
.aside-title{
  border-bottom: 2px solid $primary-color;
  color: $primary-color;
  font-size: 1.4em;
  line-height: 2;
}

.bg-white-8 {
  background: rgba(255,255,255, 0.8);
}
.bg-primary {
  background: $primary-color !important;
}
  .bg-primary-8 {
  background: rgba($primary-color, 0.9);
    transition: all 0.5s ease-in;
    &:hover{
      background: rgba($accent-color, 0.9);
    }
}
  .bg-secondary-8 {
  background: rgba($secondary-color, 0.9);
    transition: all 0.5s ease-in;
    &:hover{
      background: rgba($primary-color, 0.9);
    }
}

.btn-outline-primary {
  border-color: $primary-color !important;

  &:hover{
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.bg-secondary{
  background-color: $secondary-color !important;
}
.btn-primary {
  border-color: $secondary-color !important;
  background: $secondary-color !important;
  &:hover{
    background: $primary-color !important;
  }

  &:disabled{
    background-color: #555 !important;
    color:white !important;
    border-width:0px !important;
  }
}

  table.dataTable.no-footer{
    border-bottom: 1.5px solid $secondary-color;
  }

.dataTables_wrapper{

  .dataTables_filter{
    width: 100%;

    label{
      width: 100%;

      input{
        border: none;
        width: 100%;
        margin-left: 0px;
        border-bottom: 1.5px solid $secondary-color;
        transition: all .5s ease-in;

        &:active, &:focus{
          outline: none;
          border: none;
          border-bottom: 1.5px solid $primary-color;
        }
      }
    }
  }

  thead {
    tr{
      td{
        border-bottom: 1.5px solid $secondary-color;
      }
    }
  }

  .dataTables_paginate {
    .paginate_button{
      border: none !important;
      box-shadow: none;
      padding: 0.2em .5em;
      font-size: .9em;

      svg{
        width: 16px;
      }
    }
  }
  tbody{
    tr{
      font-size: .92em;
    }
  }
  .dataTables_info{
    font-size: .9em;
  }
}
.hide{
  display: none;
}
.text-left{
  text-align: left;
}
.overlay-image{
  position: absolute;
  height: 700px;

  img{
    height: 100%;
    width: 100%;
    // filter: blur(3px);
  }
  .bottom-shadow{
    position: absolute;
    width: 100%;
    height: 200px;
    left: 0;
    bottom:0px;
    color: #333;
    text-align: center;
    background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2), #ffffff 100%);
    padding: 0.75rem 1rem;

  }
}

.bg-section{
  background: #F2F2F2
}
.scroll-box{
  will-change: transform;
}

.section-header{
  font-weight: 700;
  color: $primary-color;

  &:after{
    content: '';
    position: absolute;
    height: 3px;
    border-radius: 10px;
    background: $secondary-color;
    width: 75px;
    left: calc(100% - 52.5%);
    margin-top: 40px;
  }

  &.text-left{
    &:after{
      content: '';
      position: absolute;
      height: 3px;
      border-radius: 10px;
      background: $secondary-color;
      width: 75px;
      left: 1.1rem;
      margin-top: 40px;
      margin-left: -8px;
    }
  }
}

.w-80{
  width: 80%;
}

.page-link {
  color: $primary-color;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
}
.mx-6{
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.bg-dark{
  background: var(--dark-color) !important;
}

.bg-dark-2{
  background: #333 !important;
}

.twitter{
  color: #2196f3 !important;
}

.facebook{
  color: #4267B2 !important;
}

.blogger{
  color: $blogger !important;
}

.ig{
  color: #405DE6 !important;
}

.youtube{
  color: #ff5722 !important;
}


.no-border-bottom{
  padding-top: .9em !important;
  padding-bottom: 1em !important;
}
.custom-ml{
  margin-left: 55px;
}

.zoom-leo {
  &:hover {
      img{
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        background: rgba(76, 76, 76, 0);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(179, 171, 171, 0)), color-stop(49%, rgba(48, 48, 48, 0.37)), color-stop(100%, rgba(19, 19, 19, 0.8)));
        background: linear-gradient(to bottom, rgba(179, 171, 171, 0) 0%, rgba(48, 48, 48, 0.71) 49%, rgba(19, 19, 19, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=0 );
        transition: all 0.5s ease;
      }
  }


}

.img-profile{
  height: 350px;
  border: 10px solid white;
}

.img-profile-2{
  height: 260px;
  border: 10px solid white;
  transition: all 0.5s ease;

}

.img-profile-3{
  height: 180px;
  border: 10px solid white;
  transition: all 0.5s ease;
}

.carousel-fade {
  .carousel-inner {
    .item {
      -webkit-transition-property: opacity;
      transition-property: opacity;
      opacity: 0;
    }

    .active {
      &.left, &.right {
        opacity: 0;
      }

      opacity: 1;
    }

    .next.left, .prev.right {
      opacity: 1;
    }

    .next, .prev {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .active {
      &.left, &.right {
        left: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .carousel-control {
    z-index: 2;
  }
}

.ml-30{
  margin-left: -30px;
}
.ml-neg-15 {
  margin-left: -15px;
}
.table {
    thead{
      th{
        font-size: .8em;
      }
    }
}
.content-layout {
    .row {
        margin-bottom: 1rem;
    }
}
.news-img{
  img{
    width: 100%;
  }
}
.title-main{
    text-shadow: 3px 3px 2px rgba(40, 40, 40, 0.50);
}

.ega-list{
  ul{
    li{
      color: $secondary-color;
      padding-bottom: 0.5em;
      padding-top: 0.5em;
      .icon{
        width: 40px;
        font-size: 18px;
        padding-top: 2px;
        color: white;
        display: table-cell;
        vertical-align: top;
      }
      .text{
        display: table-cell;
        vertical-align: middle;
      }
      a{
        span{
          color: $secondary-color;
        }
      }
      .icon{
        color: $secondary-color;
      }
    }
  }
}

.main-content {
    .content-border>.container {
        background: rgba(255, 255, 255, 0.48);
    }

    .breadcrumb-container {
        background: rgba(0, 0, 0, 0.2);
        border-bottom: solid 1px #dedede;

        .breadcrumb {
            background: transparent;
            // border: none;
            font-weight: 500;

            .active {
                color: $secondary-color;
            }

            a {
                color: #000000;
            }
        }
    }

    .sub-main-content {
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.75rem;
    }
}

.btn,
.card,
.form-control,
.input-group-addon {
    border-radius: 0;
}

.text-bold {
    font-family: 'Roboto-Bold';
}

.title-text{
    font-size: 1.2em;
}

.overflow_y-auto {
    overflow-y: auto;
}

.a{
  text-decoration: none;

  a:hover{
    text-decoration: none;
  }
}

button,.btn,.a-link {
  cursor: pointer;
  outline: none;
    &.outline {
        position: relative;
        z-index: 3;
        background: transparent;
        color: #1172c4;
        font-size: 14px;
        border-color: #1172c4;
        border-style: solid;
        border-width: 2px;
        border-radius: 22px;
        padding: 10px 40px;
        text-transform: uppercase;
        transition: all 0.2s linear;
          a {
            text-decoration: none;
        }
    }
    &.outline:hover {
        color: white;
        background: $secondary-color;
        border-color: white;
        transition: all 0.2s linear;
    }
    &.outline:active {
        border-radius: 22px;
    }
    &.white-blue {
        font-weight: 700;
        color: #00aeef;
        border-color: white;
        background: white;
    }
    &.white-blue:hover {
        color: white;
        background: #00aeef;
        border-color: white;
    }
    &.white-green {
        font-weight: 700;
        color: #7dc21e;
        border-color: white;
        background: white;
    }
    &.white-green:hover {
        color: white;
        background: #7dc21e;
        border-color: white;
    }
   &.green-white {
        font-weight: 700;
        color: #7dc21e;
        border-color: #7dc21e;
        background: transparent;
    }
    &.green-white:hover {
        color: white;
        background: #7dc21e;
        border-color: #7dc21e;
    }
    &.purple-white {
        font-weight: 700;
        color: #664e96;
        border-color: #664e96;
        background: transparent;
    }
    &.purple-white:hover {
        color: white;
        background: #664e96;
        border-color: #664e96;
    }
    &.white-purple {
        font-weight: 700;
        color: $secondary-color;
        // color: #664e96;
        border-color: white;
        background: white;
    }
    &.white-purple:hover {
        color: $secondary-color;
        // background: $primary-color-dark;
        background: white;
        border-color: $primary-color-dark;
    }
    &.white-primary {
        font-weight: 700;
        font-size: .8em;
        color: $secondary-color;
        border-color: white;
        background: white;
    }
    &.white-primary:hover {
        color: $secondary-color;
        background: transparent;
        border-color: $secondary-color;
    }
}

.w-35 {
    width: 35%;
}



.section-title {
  font-family: 'Roboto-Bold';
  color: $primary-color-dark;
  font-size: 1.875rem;
  font-weight: 500 !important;

  &:after {
    display: block;
    width: 2.5rem;
    height: 0.12rem;
    background-color: $primary-color;
    content: " ";
    margin: 1rem auto 0;
  }

  &.text-left{
    &:before {
      left: 6%;
      top: 55px;
      margin-left: 0px;
    }
    &:after {
      left: 16%;
      top: 50px;
      margin-left: -5px;
    }
  }
}


.title {
    // font-family: 'Futura Bold', serif;
    color: rgb(0, 47, 81);
    margin-bottom: 2rem;
    font-weight: 700;
    // text-shadow: 0 1.2px 2px rgb(124, 124, 124);
}

.text-small {
    font-size: 1.4rem !important;
}

.title-text {
    color: $secondary-color;
    font-size: 1em;
    border-bottom: 1px solid $secondary-color;
}


.decorate-border {
    border: 0.2rem solid $secondary-color;
}


// box shadows
.bs-0 {
    box-shadow: $box-shadow0;
}
.bs-1 {
    box-shadow: $box-shadow1;
}
.bs-2 {
    box-shadow: $box-shadow2;
}
.bs-3 {
    box-shadow: $box-shadow3;
}

.list-styled {
    li {
        display: flex;
        &::before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1.3;
            content: "\f35a";
            padding-right: 0.8rem;
        }
        &.list-styled-none {
            &::before {
                content: "";
                padding-right: 0rem;
            }
        }
    }
}

.card{
    border: none;
    box-shadow: $box-shadow0;

    &:hover{
      box-shadow: $box-shadow1;
    }

    .card-header{
      font-weight: bold;
      border: none;
    }
    .list-group-item{
      border:none !important;
    }
  .card-body-overlay{
    position: absolute;
  }
}

.card-custom {
    box-shadow: $box-shadow-card;
    .card-head {
        border-radius: 2px 2px 0 0;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
        padding: 0.7rem 1rem;
        margin-bottom: 0;
        color: #3a405b;
        font-weight: 600;
        font-family: 'Futura Bold';

    }
    .card-body {
        padding-top: 0;
    }
}

.img-gray {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    transition: all .2s;
    &:hover {
        -webkit-filter: none; /* Safari 6.0 - 9.0 */
        filter: none;
    }
}

.btn-custom {
    background: rgba(64,8,18, 1);
    color: white;
    border-radius: 0.3rem;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    // font-weight: 500;
    min-width: 7.7rem;
    border-radius: 40px;
    padding: 0.3rem 3rem;
    &:hover {
        color: white;
        background-color: $secondary-color;
    }
}


a{
    &.link-black {
        color: black;
        transition: all .2s;
        &:hover {
            color: #0000ff;
        }
    }
    &.read-more {
        position: relative;
        border: inherit;
        transition: .2s;

        &:after {
            // position: absolute;
            // top: 0;
            // bottom: 0;
            // right: 0;
            // border: inherit;
            // min-width: 30%;
            // margin-right: -32%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // font-family: 'FontAwesome';
            // content: "\f054";
            // font-size: 1.4rem;
            // transition: .2s;
            // background: inherit;
            // transition: color 0.5s;
            // box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        }

        &:hover:after {
            -webkit-animation: bounceright .3s alternate ease infinite;
            animation: bounceright .3s alternate ease infinite;
	    }
    }
}

.xs-show{
  display: none;
}

@media (min-width: 1920px) {
  .img-frame{
    max-height: 600px !important;
  }

  .lg-hide{
    display: none;
  }

  .img-profile-2{
    height: 280px;
  }

  .img-profile-3{
    height: 180px;
  }

  .h-xl-200{
    height: 200px;
  }

}

  .form-control:focus {
    border-color: $primary-color;
    outline: 0;
    box-shadow: none;
  }

  .first-letter {
    &::first-letter{
      font-size: 40px;
      font-weight: bold;
      color: $primary-color;
      float: left;
      line-height: 80%;
      padding-top: 4px;
      padding-right: 8px;
      padding-left: 3px;
    }
  }
.xl-show{
  display: none;
}

@media (max-width: 1440px) {
  .img-profile-2{
    height: 270px;
  }
  .img-profile-3{
    height: 180px;
  }
}

@media (max-width: 1360px) {
  .img-profile-2{
    height: 270px;
  }
  .img-profile-3{
    height: 170px;
  }
}

@media (max-width: 1280px) {
  .img-profile-2{
    height: 260px;
  }
  .img-profile-3{
    height: 160px;
  }
}


@media (min-width: 1200px){
  .container{
    max-width: 1200px;
  }
}

@media (min-width: 1400px){
  .container{
    max-width: 1300px;
  }
}
  @media (min-width: 1600px){
    .container{
      max-width: 1400px;
    }
  }

  @media (min-width: 1920px) {
    .container{
      max-width: 1800px;
    }
    .xl-show{
      display: block !important;
    }
  }
@media (max-width: 1200px) and (min-width: 1024px) {

  .img-frame{
    // height: 300px !important;
    width: 100%;
    left: -4.5%;
    margin: .5vh 1vw;

  }

  .sm-hide{
    display: block;
  }
  .h-sm-60{
    height: 60px !important;
  }
  .h-sm-130{
    height: 130px !important;
  }
  .sm-show{
    display: block;
  }
  .title{
    //font-size: 1.2em;
  }
}

@media (max-width: 1150px) and (min-width: 768px) {
  .md-hide{
    display: none !important;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .img-profile-2{
    height: 170px;
  }
  .img-profile-3 {
    height: 100px;
  }

  .h-sm-250{
    height: 240px !important;
  }
  .h-sm-60{
    height: 60px !important;
  }
  .h-sm-130{
    height: 130px !important;
  }
  .post-slide14 .post-bar {
    height: 59px !important;
    border-radius: 0% !important;
    background: #f13936;
    line-height: 34px;
    width: 100% !important;
  }

}

  @media (min-width: 728px) {
    .lg-hide{
      display: none;
    }
  }

@media (max-width: 992px) {
  .container{
    padding: 0px;
  }
  .modal .modal-lg {
    max-width: 100%;
  }
  .section-title{
    &:after, &:before{
      display: none;
    }
  }
  .mx-px-0{
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .mx-xs-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .justify-xs-content-center {
    justify-content: center;
    display: grid;
  }
  .pt-sm-0{
    padding-top: 0px !important;
  }
  .img-frame{
    box-shadow: none !important;
    margin: 0.5vh .5vw !important;
    height: 220px !important;
  }

  .overlay-image{
    display: none;
  }
  .w-20{
    width: 20% !important;
  }

  .w-80{
    width: 80% !important;
  }
  .xs-dark{
    color: #333 !important;
  }
  .float-xs-right{
    float: right !important;
  }
  .float-xs-left{
    float: left !important;
  }
  .ega-heading-2{
    margin-bottom: 10px !important;
  }
  .pl-xs-2{
    padding-left: 10px !important;
  }
  .pl-xs-3{
    padding-left: 20px !important;
  }

  .px-xs-2{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pt-xs-0{
    padding-top: 0px !important;
  }
  .pt-xs-3{
    padding-top: 20px !important;
  }
  .px-xs-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .px-pt-2{
    padding-top: 10px;
  }
  .px-mb-0{
    margin-bottom: 0rem !important;
  }
  .mt-xs-0{
    margin-top: 0px !important;
  }
  .mt-xs-2 {
    margin-top: 2rem;
  }
  .news{
    padding-bottom: 10px;
    margin-bottom: 10px !important;
  }
  .no-bs{
    box-shadow: none !important;
  }
  .col-xs-2{
    width: 20% !important;
  }
  .col-xs-3{
    width: 30% !important;
  }
  .col-xs-8{
    width: 60% !important;
  }
  .col-xs-9{
    width: 70% !important;
  }
  .col-xs-10{
    width: 80% !important;
  }
  .w-xs-75{
    width: 75% !important;
  }
  h4.title{
    font-size: 1rem;
  }
  .sm-hide {
    display: none;
  }
  .xs-show {
    display: block;
  }
  .text-xs-center{
    text-align: center !important;
  }

  .what-we-do{
    .container-fluid{
      padding-left: 25px;
    }
  }

  .navbar-light {
    .navbar-toggler {
      padding-left: 15px;
      color: rgb(255,255, 255);
      border-color: rgba(0, 0, 0, 0) !important;
    }

  }
}

@media (max-width: 576px) {
    html {
        font-size: 0.9em;
    }
    body{
      padding-right: 0px !important;
      padding-left: 0px !important;
      overflow-x: hidden;
    }
    .img-frame{
      box-shadow: none !important;
      // margin: 2.5vh 3vw !important;
      height: 260px !important;
    }
    .img-profile-3 {
      height: 280px;
    }
    .order-xs-1{
      order: 1;
    }
    .order-xs-2{
      order: 2;
    }
    .w-20{
      width: 20% !important;
    }

    .w-80{
      width: 80% !important;
    }
    .mx-px-0{
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
    .my-xs-2{
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .xs-dark{
      color: #333 !important;
    }
    .text-xs-left{
      text-align: left !important;
    }
    .float-xs-right{
      float: right !important;
    }
    .float-xs-left{
      float: left !important;
    }
    .ega-heading-2{
      margin-bottom: 10px !important;
    }
    .pl-xs-0{
      padding-left: 0px !important;
    }
    .pl-xs-3{
      padding-left: 20px !important;
    }
    .pt-xs-3{
      padding-top: 20px !important;
    }
    .pt-xs-0{
      padding-top: 0px !important;
    }
    .px-xs-0{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .pt-xs-0{
      padding-top: 0px !important;
    }
    .h-xs-230{
      height: 230px !important;
    }
    .news{
      padding-bottom: 10px;
      margin-bottom: 10px !important;
    }
    .no-bs{
      box-shadow: none !important;
    }
    .col-xs-2{
      width: 20% !important;
    }
    .col-xs-8{
      width: 60% !important;
    }
    h4.title{
      font-size: 1rem;
    }
    .sm-hide {
        display: none;
    }
    .xs-show {
        display: block;
    }
    .mb-xs-2{
      margin-bottom: 10px;
    }
    .nav-bottom-header{
      padding-left: 10px;
    }
}

.shadow {

	background-color: #f5f5f5;

  .shadow-top {
    box-shadow: 0px -15px 10px -15px #111;
  }
  .shadow-bottom {
    box-shadow: 0px 15px 10px -15px #111;
  }
  .shadow-left {
    box-shadow: -15px 0px 10px -15px #111;
  }
  .shadow-right {
    box-shadow: 15px 0px 10px -15px #111;
  }

}

  ::-webkit-scrollbar-track  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  ::-webkit-scrollbar  {
    width: 10px;
    background-color: $primary-color;
  }

  ::-webkit-scrollbar-thumb  {
    background-color: $secondary-color;
  }


.border-primary {
  border: 1px solid $primary-color !important;
}
.border-right-primary {
  border-right: 1px solid $primary-color !important;
}
.border-bottom-primary {
  border-bottom: 1px solid $primary-color !important;
}
.custom-btn-primary {
  color: #fff;
  background-color: rgba($primary-color, 0.9);
  border-color: rgba($primary-color, 0.9);
}
.primary-link {
  color: $primary-color !important;
  text-decoration: none;
  cursor: pointer;
}
.primary-link:hover {
  color: $secondary-color;
  transition: all .5s ease;
}
.normal-section-height {
  height: 500px !important;
}
