//fast colors and utilities...
.c-red{
    color: red;
} 

.c-green{
    color: green;
}

.bold-600{
    font-weight:600;
}

.bg-primary-dark{
    background-color: $primary-color-dark;
}

.bg-secondary-dark{
    background-color: $secondary-color-dark;
}

.hover-primary{
    &:hover{
        color: $primary-color;
    }
}

.bg-darker{
    background: var(--darker-color) !important;
  }

.bg-dark-faded{
    background-color: rgba($dark-color,0.6);
}

.bg-darker-faded{
    background-color: rgba($darker-color,0.6);
}

.bg-white-trans{
    background-color: rgba($white,0.8);

    // for fixed top remove opacity
    &.fixed-top{
        background-color: rgba($white,0.95);
    }
}



//continue
// for the number on folders in publications
.text-folder{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.main-container{
    padding-bottom: 35px !important;
}

.page-content {
    min-height: 400px;
}

.main-heading{
    color: $primary-color;
    //border-bottom: 2px solid $primary-color-dark;
    //padding-bottom: 5px;
    font-weight: bold;
}

.main-heading-border{
    width: 50%;
    border-bottom: 3px solid $primary-color-dark;
    margin-bottom: 20px;
}

.download-link{
    transition: all 0.3s;
    background-color: pink;
    transform: translateY(-4px);

    .fa{ transition:all 0.3s; position: absolute; right:0px; top:0px;}
    .fa:nth-child(1){  opacity: 1; }
    .fa:nth-child(2){ opacity:0; }

    &:hover{
       // top:3px;
        transform: translateY(4px);
  

        .fa:nth-child(1){ opacity: 0; }
        .fa:nth-child(2){ opacity:1; }
       // .fa{transform: scale(1.05);}
    }
}

.hover-color-primary{
    color:initial;
    transition:all 0.3s;

    &:hover{
        color: $primary-color !important;
    }
}


.opacity-1{
    opacity: 1 !important;
}

.links-faded a{
    opacity: 0.6 !important;
}



.other-heading{
    color: $secondary-color-dark;
    font-weight: 600;
    padding-bottom: 5px;
}

.text-break{
    @media (min-width: 576px){
        column-count: 2;
        column-gap: 35px;
        column-rule: 2px solid #ccc;
    }
}

//for button links...
.btn-action{
    background-color: $white;
    color:$primary-color-light;
    border-radius: 5%;
    border: 1px solid $primary-color;
    padding: 10px 20px;
}

.btn-action:hover{
    background-color: $primary-color-light;
    color:$white;
}

.btn-action-home > div{
    background-color: $white;
    color:$primary-color-light;
}

.btn-action-home > div:hover{
    border: 2px solid $primary-color;
    background-color: $primary-color-light;
    color:$white;
}


//@ for creating a blink item
.new-star{
    color: red;
    font-size: 2em;
    animation: CSSblink linear 1s infinite;
}

.rightCSS a:nth-child(even) .new-star{
    animation: CSSblink2 linear 1s infinite;
}

@keyframes CSSblink {
    0% { opacity: 0 }
    50% { opacity: 1 }
    100% { opacity: 0 }
}
@keyframes CSSblink2 {
    0% { opacity: 1 }
    50% { opacity: 0 }
    100% { opacity: 1 }
}

//@ end of creating blink item...


//for carousel on the homepage.......
#homeCarousel{
    
    .carousel-caption{
        background-color: rgba($primary-color-dark, 0.75);
        //opacity: 0.7;
        left: 0 !important;
        right:0 !important;
        bottom:0 !important;
        color: #fff;
    }

    .full-hd-container{
        width:100%;
        background-color: #222222;
        background-position: center;
        background-size: cover;
        position:relative;
        overflow: hidden;
      
        &:after {
          content: "";
          display: block;
          padding-bottom: 56.25%;
        }
    
        img{
            position:absolute;
            width:100%;
            height:auto;
        }
    }

    
    @include media-breakpoint-up(sm) { 
            
        .full-hd-container{
            width:100%;
            background-color: #222222;
            background-position: center;
            background-size: cover;
            position:relative;
            overflow: hidden;
        
            &:after {
            content: "";
            display: block;
            padding-bottom: 56.25%;
            }
        
    
        }

     }

     @include media-breakpoint-up(md) {             
        .full-hd-container{
            width:100%;
            background-color: #222222;
            background-position: center;
            background-size: cover;
            position:relative;
            overflow: hidden;
        
            &:after {
                content: "";
                display: block; 
                // padding-bottom: 50%;
                padding-bottom: 56.25%;
            }
        
    
        }

     }
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    background-color: $primary-color;
    padding: 15px;
    border: 2px solid $primary-color;
    border-radius: 100%;

    &.control-small{
        padding: 0px;
    }
}


//member carousel....
#memberCarousel{
    img{
        width: 100px !important;
        max-height: 120px !important;
    }
}

//end of member carousel onhomepage
  

.text-dark{
    color: $dark-color !important;
}

.full-hd-container{
    width:100%;
    background-color: #222222;
    background-position: center;
    background-size: cover;
    position:relative;
    overflow: hidden;
  
    &:after {
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }

    img{
        position:absolute;
        width:100%;
        height:auto;
    }
}



.square-container{
    width:100%;
    background-color: #222222;
    background-position: center;
    background-size: cover;
    position:relative;
    overflow: hidden;
  
    &:after {
      content: "";
      display: block;
      padding-bottom:100%;
    }

    img{
        position:absolute;
        width:100%;
        height:auto;
    }
}

.slightly-square-container{
    width:100%;
    background-color: #222222;
    background-position: center;
    background-size: cover;
    position:relative;
    overflow: hidden;
  
    &:after {
      content: "";
      display: block;
      padding-bottom:75%;
    }

    img{
        position:absolute;
        width:100%;
        height:auto;
    }
}



.round-container{
    width:100%;
    background-color: #222222;
    background-position: center;
    background-size: cover;
    position:relative;
    overflow: hidden;
    border-radius: 50%;
  
    &:after {
      content: "";
      display: block;
      padding-bottom:100%;
    }

    img{
        position:absolute;
        width:80%;
        height:auto;
    }
}


.full-3-quarter-container{
    width:100%;
    background-color: #222222;
    background-position: center !important;
    background-size: cover !important;
    position:relative;
    overflow: hidden;
  
    &:after {
      content: "";
      display: block;
      padding-bottom: 75%;
    }

    img{
        position:absolute;
        width:100%;
        height:auto;
    } 
}

.dark-overlay{
    position: absolute;
    width: 100%;
    height:100%;
    background-color:rgba(#333,0.4);
}

.blend-overlay{
    position: absolute;
    width: 100%;
    height:100%;
    background-color:rgba(#333,0.4);
}

.image-with-grayscale{
    img {
        filter: $secondary-color; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
      }
      
      /* Disable grayscale on hover */
      &:hover img {
        -webkit-filter: grayscale(0);
        filter: none;
      }
}

.image-with-overlay{
    position: relative;

    img{
        mix-blend-mode:darken;
    } 
}

.image-with-overlay::after{
    content:"";
    position: absolute;
    top:0;left:0;
    width:100%;
    height:100%;
    display: block;
    // background-color: rgba($white, 1);
    mix-blend-mode:saturation;
}

//colors

.bg-light-dark{
    background-color: #eee;
}

.bg-accent{
    background-color: rgba($accent-color,1) !important;
}

.bg-secondary-faded{
    background-color: rgba($secondary-color,0.5) !important;
}

.box-shadow{
    -webkit-box-shadow: 0px 13px 16px 0px rgba(0, 0, 0 , 0.08);
    -moz-box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 13px 16px 0px rgba(0, 0, 0 , 0.08);
}

.chatbot-container{
    .bot-header {
        overflow:hidden;
        display:flex;
        justify-content: end;
    }

    .bot-header-text{ transition: all 0.35s; opacity:0; }
    .when-active,.when-inactive{transition: all 0.3ss; transition-delay: 0.25s;}
    .when-inactive{transform:scale(1); opacity: 1;}
    .when-active{transform:scale(0.8); opacity: 0;}
    
    .bot-body{ 
        transition: height 0.3s, max-height 0.3s, min-height 0.3s,padding-top 0.35s, padding-bottom 0.35s;
        max-height:0;
        padding-top:0 !important;
        padding-bottom:0 !important;
        box-sizing: border-box;
    }
    
    .bot-toggle {
        position:relative ;
        &::before{
            content: "";
            width: 100%; height:100%;
            position: absolute;
            top:0;left:0;
            background:rgba(0,0,0,0);
        } 
        transition:background 0.3s;
        
        &:hover{
            &::before{
                background:rgba(0,0,0,0.3);
            } 
        }
    }
    
    // .toggle-arrow{
    //     transition:all 0.4s; 
    //     transform:rotate(180deg);
    //     transition-delay:0.2s;
    // }

 
}

.chatbot-container.active{
    .bot-body{
        max-height:600px; 
        padding-top:0.5em !important;
        padding-bottom:1.5em !important;
    }
    // .toggle-arrow{ transform:rotate(0deg) }
    .bot-header-text{  opacity:1;}
    .when-active{transform:scale(1); opacity: 1;}
    .when-inactive{transform:scale(0.8); opacity: 0;}
}

.feedback-box{
    .paper{
        transition:opacity 0.2s, transform 0.6s;
        transition-timing-function: ease-in-out;
        transform: rotateX(90deg) translateY(-300px) ;
        // opacity:0;
    }

    &:hover {
        .paper{
            opacity:1;
            transform:rotateX(0deg) translateY(100px);
        }
    }
}



///@at-root
.zoom-container{
    img, .image{
        transition:transform 0.3s;
        transform:scale(1.0);
    }
    
    &:hover .image{
        transform:scale(1.1);
    }

}

.zoom-container.zoom-slightly{
    &:hover .image{ transform:scale(1.03);  }
}

.zoom-image{
    transition:transform 0.3s;
    transform:scale(1.0);

    &:hover{
        transform:scale(1.1);
    }
}





///// elements

.lines-section{
    .line, .top-line , .bottom-line {
        width:60px;
        height:3px;
        position:absolute;
        transition:margin-left 0.4s, margin-right 0.4s;
    }
    
    .top-line {
        left:0px;
        top:0px;
        margin-left:0px;

    }
    
    .bottom-line {
        right:0px;
        bottom:0px;
        margin-right:0px;
    }
    
  
}


.welcome-header{
    position: relative;

    @include media-breakpoint-up(md) { 
        left:-10%;
        top:-30px;
     }
}


.section-heading{
    .text {
        opacity: 1;
        transition: opacity 0.4s, margin 0.5s;
    }

    .view-all{
        transition: opacity 0.4s, margin 0.5s;
        top:0;
        margin-left:90%;
        opacity: 0;
        display:flex;
        height: 100%;
        align-items: center;
    }

    .fa{
        align-self: center;
    }


    &:hover{
        .text{opacity: 0.7;}
        .top-line{margin-left:50%}
        .bottom-line{margin-right:50%}
        .view-all{opacity: 1; margin-left: 105%;}
    }
}

.hover-shadow{
    transition:box-shadow 0.4s, top 0.3s;
    box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0);
    top:0;
    position:relative;

    &:hover{
        box-shadow: 0 0.5rem 0.2rem rgba(0, 0, 0, 0.15);
        top:-0.5rem;
    }
}

.hover-border{
    transition:border 0.4s;
    border:1px solid rgba(0, 0, 0, 0);


    &:hover{
        border:1px solid rgba(0, 0, 0, 0.15);
    }
}

.hover-slide{
    position: relative;
    transition:left 0.3s;
    left:0px;

    &:hover{
        left:5px;
    }
}

.pointer-hover{
    overflow: hidden;

    .fa{
        transition:left 0.3s,transform 0.3s,opacity 0.3s;
        position: relative;
        
    }

  

    .fa:nth-child(1){
        left:-50px;
    }

    .fa:nth-child(2){
        transform:scale(1,1);
        opacity: 1;
        position:absolute;
    }

    // .fa-play{
    //     left:0px !important;
    //     position: relative !important;
    // }

    .container-overlay{
        opacity: 0.5;
    }

    &:hover{
        .fa:nth-child(1){
            left:0;
        }

        .fa:nth-child(2){
            transform:scale(0.5,0.5);
            opacity: 0;
        }

        // .fa-play{
        //     left:0px !important;
        //     position: relative !important;
        // }

        .play-bg{
            border-radius: 10%;
            width:80px;
        }
        
        .container-overlay{
            opacity: 1;
        }
    }
}

.container-overlay{
    background: linear-gradient(to bottom, rgba($primary-color-dark, 0) 0%, rgba($primary-color,0.8) 100%);
    transition: opacity 0.4s, background 0.4s;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    position:absolute;
    left:0;top:0;
}

.has-show-on-hover{
    .show-on-hover{transition: all 0.3s;opacity:0;}
    &:hover .show-on-hover {opacity:1}
}

.text-accent{
    color:$accent-color !important;
}

.show-more-content{
    position: relative; 
    overflow: hidden;
    transition:color 0.3s;
    color:white;
   // border:1px solid black;

    .container-overlay{
        opacity: 0;
        transition: opacity 0.4s, background 0.4s;
        transition-delay: 0.1s;
        background: linear-gradient(to bottom, rgba($secondary-color, 0) 0%, rgba($secondary-color,0.6) 100%);
    }

    .dark-overlay{
        transition:background 0.4s,opacity 0.4s;
        background-color:rgba(#333,0.4);
    }

    .blend-overlay{
        transition:background 0.4s,opacity 0.4s;
        background-color:rgba(#333,0.4);
        mix-blend-mode: color;
    }

    .content{
        position: relative;
        transition: top 0.3s;
        top:0%;
    }

    .more-content{
        transition: top 0.35s;
        position: absolute;
        top:0; left:0;
        width:100%;
        height:100%;
        top:100%;
       // background-color: pink;
    }

    &:hover{
        color: rgba($secondary-color, 1) !important;
        .more-content{ top:40%}
        .content{ top:-50%}
        .content.slight-move{ top:-20%}
        .container-overlay{ opacity:0.1}
        .dark-overlay{ background-color:rgba(#fff,0.7); }
        .blend-overlay{ background-color:rgba(#333,1); }
    }
}

.show-more-content-news{
    position: relative;
    overflow: hidden;
    transition:color 0.3s;

    .dark-overlay{
        transition:background 0.4s,opacity 0.4s;
        background-color:rgba(#000,0);
        position: absolute;
        top:0; left:0;
        width:100%;
        height:100%;
    }

    .content{
        position: relative;
        transition: top 0.3s;
        top:0%;
    }

    .more-content{
        transition: top 0.3s,opacity 0.35s;
        position: absolute;
        top:100%;
        margin: auto;
        left: 0;
        margin-top:-1em;
        width:100%;
        background-color: inherit;

        .text{ 
            transition: opacity 0.35s;
            opacity: 0;
        }

    }

    &:hover{
        .more-content .text{ opacity:1}
        .content{ top:-20%}
        .dark-overlay{ background-color:rgba(#000,0.4); }
    }
}

.contact-socials {
    display: flex;

    a{
        color:initial !important;
        transition:all 0.3s !important;
        background-color:rgba($primary-color, 0);
        display: flex;
        width:40px;
        height:40px;
        margin:4px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border:1px solid rgba($secondary-color, 0.5);
        position:relative;
        top:0px;
        transform:scale(1);

        &:hover {
             //color: rgba($primary-color, 1) !important;
             background-color:rgba($primary-color, 0.7);
           //  top:-3px;
             border-radius: 5%;
         }
    };

    
}

.map-container{
    position: relative;
    background-color: rgba($secondary-color, 0.8);
    transition:all 0.3s;
    overflow: hidden;

    iframe{
        opacity:0.9;
        transition:all 0.3s;
        transform:scale(0.99);
    }

    &:hover{
        iframe{ opacity:1; transform:scale(1); }
    }


}


.h-auto{
    height:fit-content !important;
}
.w-lg-100 {
    width: 100% !important;
}

.w-md-50{
    @include media-breakpoint-up(md){ width:50%}
    @include media-breakpoint-up(lg){ width:100%}
}

.play-bg{
    width:50px;
    height:50px;
    border-radius: 100%;
    font-size: 20px;
    color:white;
    overflow:hidden;
    transition: width 0.3s, height 0.3s, border-radius 0.3s;
}

.list-item{
    min-height: 50px;
}

.list-item-small {
    min-height: 40px;
}

.item-date{
    bottom: 0px;
    left: -5px;
    font-size: 11px !important;
   // opacity: 0.6;
}

.event-hover{
    

    .fa,.event-date{
        transition:left 0.3s,transform 0.3s,opacity 0.3s;
    }

    .fa{
        transform:translateX(-50px);
    }

    .position-absolute{
        left:0px; right:0px;
        top:0px; bottom:0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .event-date{
        transform:scale(1,1);
        opacity: 1;
    }
    

    &:hover{
        .fa{
            transform:translateX(0px);
        }

        .event-date{
            transform:scale(0.5,0.5);
            opacity: 0;
        }

    }
}

.calendar-pad{
    width:50px;
    height:50px;
    border-radius: 50%;
    border:1px solid $secondary-color;
    background-color:$primary-color; 
    color:white !important;
    top:-5px; right:-5px;
    overflow: hidden;
    z-index: 2;
  
    .date-day{
      font-weight: bold;
      font-size: 13 !important ;
    }
  
    .date-month{
      font-size: 10px !important;
     // opacity: 0.6;
    }
  }

.calendar-pad-2{
    width:60px;
    height:60px;
    border-radius: 50%;
    border:1px solid $secondary-color;
    background-color:$primary-color; 
    color:white !important;
  
  
    .date-day{
      font-weight: bold;
      font-size: 14 !important ;
    }
  
    .date-month{
      font-size: 10px !important;
      //opacity: 0.6;
    }
  }
  

.hover-scale {
    position: relative;
    transition: transform 0.2s;
    transform:scale(1,1);
    font-weight: normal !important;

    &:hover{
        transform:scale(1.04,1.04);
    }
}

a.text-primary:hover{
    color: $secondary-color !important;
}

.height-fit-content{
    height:fit-content;
}



.hover-bg{
    transition:background 0.4s;
    background: rgba(0, 0, 0, 0);

    &:hover{
        background:rgba($secondary-color, 0.2);
    }
}


.hover-bg-secondary{
    transition:background 0.4s;
    background: rgba(0, 0, 0, 0);

    &:hover{
        background:rgba($secondary-color, 1);
    }
}

.hover-bg-secondary-faded{
    transition:background 0.4s;
    background: rgba($secondary-color, 0);

    &:hover{
        background:rgba($secondary-color, 0.5) !important;
    }
}

.hover-bg-transparent-primary{
    transition:all 0.4s ease !important;
    background: rgba($primary-color, 0);

    &:hover{
        background:rgba($primary-color, 0.5);
    }
}

.hover-bg-primary{
 transition:all 0.4s ease !important;
    background: rgba($primary-color, 0);

    &:hover{
        background:rgba($primary-color, 1);
    }
}

.hover-color-primary{
    transition:all 0.3s !important;
    color:initial;

    &:hover {
        color:$primary-color;
    }

}

.cursor-pointer{
    cursor:pointer;
}

.rounded-small{
    border-radius: 15px;
}


.rounded-slight{
    border-radius: 5px;
}

.rounded-left-slight{
    border-radius: 8px 0 0 8px;
}

.rounded-medium{
    border-radius: 20px;
}

.hover-scale-down-icon{
    .icon{transition:transform 0.3s; transform: scale(1);}
    &:hover .icon{transform:scale(0.97);}
}


.readmore{
    .fa{
        transition: left 0.4s, opacity 0.3s;
        position:relative;
        opacity: 0;
        left:0;
    }

    &:hover{
        .fa{
            left:10px;
            opacity: 1;
        }
    }
}

///borders


.border-bottom-secondary{
    border-bottom:3px solid $secondary-color;
}

.border-bottom-accent{
    border-bottom:3px solid $accent-color;
}


.border-bottom-primary-thick{
    border-bottom:3px solid $primary-color;
}

.border-bottom-secondary-thick{
    border-bottom:3px solid $secondary-color;
} 

.border-bottom-white-thick{
    border-bottom:3px solid $white;
} 

.border-bottom-white{
    border-bottom:1px solid $white;
} 

.border-bottom-secondary-thin{
    border-bottom:1px solid $secondary-color !important;
}


.border-bottom-secondary-medium{
    border-bottom:2px solid $secondary-color !important;
}


.border-top-secondary-medium{
    border-top:2px solid $secondary-color !important;
}

.border-top-secondary-faded{
    border-top:1px solid rgba($secondary-color,0.2) !important;
}

.border-top-primary{
    border-top:1px solid $primary-color !important;
}

.input-plain{
    border:none;
    background:none;
    outline:none;
}

.even-no-border-left:nth-child(even){
    border-left: unset !important;
}

.first-2-md-no-border-top:nth-child(1),.first-2-md-no-border-top:nth-child(2){
    @media (min-width: 576px){ 
        border-top:unset !important;
    }
}

.first-3-md-no-border-top:nth-child(1),.first-3-md-no-border-top:nth-child(2),.first-3-md-no-border-top:nth-child(3){
    @media (min-width: 576px){ 
        border-top:unset !important;
    }
}

.border-color-secondary{
    border-left-color:rgba($secondary-color, 1) !important;
    border-top-color:rgba($secondary-color, 1) !important;
    border-bottom-color:rgba($secondary-color, 1) !important;
    border-right-color:rgba($secondary-color, 1) !important;
}

.border-color-primary{
    border-left-color:rgba($primary-color, 1) !important;
    border-top-color:rgba($primary-color, 1) !important;
    border-bottom-color:rgba($primary-color, 1) !important;
    border-right-color:rgba($primary-color, 1) !important;
}


.border-color-primary-faded{
    border-left-color:rgba($primary-color,0.3 ) !important;
    border-top-color:rgba($primary-color, 0.3) !important;
    border-bottom-color:rgba($primary-color, 0.3) !important;
    border-right-color:rgba($primary-color, 0.3) !important;
}

.border-width-3{
    border-width: 3px !important;
}

.border-width-5{
    border-width: 5px !important;
}

.border-color-black{
    border-left-color:rgba(#666, 0.5) !important;
    border-top-color:rgba(#666, 0.5) !important;
    border-bottom-color:rgba(#666, 0.5) !important;
    border-right-color:rgba(#666, 0.5) !important;
}

.border-right-secondary-thick{
    border-right:3px solid rgba($secondary-color, 1) !important;
}

.border-left-secondary-thick{
    border-left:3px solid rgba($secondary-color, 1) !important;
}

.thirds-no-border-left:nth-child(3n+1){
    border-left: unset !important;
}

.odd-no-border-left:nth-child(odd){
    border-left: unset !important;
}

.border-left-primary{
    border-left:3px solid $primary-color !important;
}

.border-left-primary-thick{
    border-left:3px solid $primary-color !important;
}

.border-left-primary-thick-half{
    position: relative;
    &::before{
        content:"";
        position:absolute;
        background: rgba($primary-color,1);
        left:0px;
        top:0px;
        bottom:0px;
        margin:auto;
        display:block;
        width:3px;
        height:90%;
    }
}


.border-bottom-primary{
    border-bottom:2px solid $primary-color !important;
}

.border-bottom-primary-thin{
    border-bottom:1px solid $primary-color !important;
}

.border-bottom-faded{
    border-bottom:1px solid rgba(100, 100, 100, 0.1) !important;
}







.border-frame {

    background: #fff;
    border-style: solid;
    border-width: 12px;
    border-top-color: #ddd;
    border-right-color: #f7f7f7;
    border-bottom-color: #ddd;
    border-left-color: #f7f7f7;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

//// positioning

.absolutely-center{
    position:absolute;
    top:0px;bottom:0px;
    left:0px;right:0px;
    margin:auto;
}





.divide:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60%;
    height: 100%;
    background: $primary-color;
    -webkit-clip-path: polygon(0 0,100% 0,60% 100%,0 100%);
    clip-path: polygon(0 0,100% 0,60% 100%,0 100%);
  }

 
  .divide-2:before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($primary-color,1);
    -webkit-clip-path: polygon(20% 100%, 25% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(20% 100%, 22% 0, 100% 0, 100% 100%, 0 100%, 0 100%);


  }

  .divide-2::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 1px;
    width: 100%;
    height: 100%;
    background: rgba($secondary-color,1);
    -webkit-clip-path: polygon(40% 100%, 45% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    clip-path: polygon(40% 100%, 42% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }


  .divide-3::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($primary-color-light,1);
    -webkit-clip-path: polygon(5% 95%, 94% 95%, 100% 74%, 100% 100%, 0 100%, 0 74%);
    clip-path: polygon(5% 95%, 94% 95%, 100% 74%, 100% 100%, 0 100%, 0 74%);
  }

  
  .divide-3::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    transform:translateY(1px);
    width: 100%;
    height: 60%;
    background: rgba($secondary-color-dark,1);
    -webkit-clip-path: polygon(5% 95%, 94% 95%, 100% 74%, 100% 100%, 0 100%, 0 74%);
    clip-path: polygon(5% 95%, 94% 95%, 100% 60%, 100% 100%, 0 100%, 0 60%);
  }

  .divide-4::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($secondary-color,1);
    -webkit-clip-path: polygon(5% 95%, 94% 95%, 100% 74%, 100% 100%, 0 100%, 0 74%);
    clip-path: polygon(5% 95%, 94% 95%, 100% 74%, 100% 100%, 0 100%, 0 74%);
  }

  
  .divide-4::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    transform:translateY(1px);
    width: 100%;
    height: 60%;
    background: rgba($primary-color-dark,1);
    -webkit-clip-path: polygon(5% 95%, 94% 95%, 100% 74%, 100% 100%, 0 100%, 0 74%);
    clip-path: polygon(5% 95%, 94% 95%, 100% 60%, 100% 100%, 0 100%, 0 60%);
  }

  .slash::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($secondary-color,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(95% 0, 100% 0, 21% 100%, 17% 99%);
  }

  
  .slash-color-1::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($accent-color,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(95% 0, 100% 0, 21% 100%, 17% 99%);
  }

  .slash-color-primary::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($primary-color,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(95% 0, 100% 0, 21% 100%, 17% 99%);
  }

  .slash-color-dark::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(#333,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(95% 0, 100% 0, 21% 100%, 17% 99%);
  }

  .slash-2::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($secondary-color,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(90% 0, 100% 0, 21% 100%, 10% 100%);
  }

  
  .slash-2-color-1::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($accent-color,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(90% 0, 100% 0, 21% 100%, 10% 100%);
  }
    
  .slash-2-color-dark::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(#333,1);
    -webkit-clip-path: polygon(52% 0, 100% 0%, 49% 100%, 0% 100%);
    clip-path: polygon(90% 0, 100% 0, 21% 100%, 10% 100%);
  }


  .slash-3::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($secondary-color,1);
    -webkit-clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
    clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
  }

  
  .slash-3-color-1::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($accent-color,1);
    -webkit-clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
    clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
  }
    
  .slash-3-color-dark::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(#333,1);
    -webkit-clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
    clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
  }

  .slash-3-color-primary::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba($primary-color,1);
    -webkit-clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
    clip-path: polygon(4% 0, 100% 0, 96% 100%, 0 100%);
  }

  .pages-decorater-top{
     // border-top: 3px solid rgba($secondary-color, 0.5);
      position: relative;
    


      @include media-breakpoint-up(lg) { 
      //  border-top: 3px solid rgba($secondary-color, 0.5);
        background-color:rgba(#eee, 1);
        width:100%;
        height:200px;
      }

    //   &::before{
    //     content:"";
    //     display: block;
    //     position:absolute;
    //     bottom:10%;left:0;
    //     height: 100%;
    //     width:100%;
    //     background: rgba(#ddd, 1);
    //     clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 59%);
    //   }
    
    
    //   &::after{
    //     content:"";
    //     display: block;
    //     position:absolute;
    //     bottom:0;left:0;
    //     height: 100%;
    //     width:100%;
    //     background: rgba(#999, 1);
    //     clip-path: polygon(100% 0, 100% 0, 100% 100%, 0 100%, 0 90%);
    //   }
  }
  
  .pages-decorater-bottom{
      @include media-breakpoint-up(lg) { 
        border-radius:15px 15px 0px 0px;
        position:relative;
        margin-top:-120px;
      }
  }



.bg-primary-faded {
    background-color: rgba($primary-color, 0.3);
}


.bg-primary-slightly-faded {
    background-color: rgba($primary-color, 0.7);
}





//////////////////////////////////////// the new site

.top-right{
    top:0;
    right:0;
}

.top-left{
    top:0;
    left:0;
}

.search-form-container{
    transition: height 0.3s;
    height:100% ;
    // background-color: pink;
    display: none;
    --order:1.5;

    .search-form{
        height: 100px;
        margin-top:-8px;
        opacity:0;
        transition: margin 0.3s, padding 0.3s, opacity 0.7s;
    }
  

    &.active{
        height:100% ;
        .search-form{
             opacity: 1;
             margin-top: 0px;
        }
        display: block;
      
       
    }

}

 