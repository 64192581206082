.sidebar-left{
  div > a{
    //   margin-bottom: 5px;
      background-color: white;
      color: $primary-color;
      font-weight: 600;
      
      &:hover{
          background-color: $primary-color;
          color: white;
      }

      &.active{
          background-color: $primary-color;
          color: white;
        //  border: 1px solid $primary-color;
      }

      &.active:hover{
          background-color: $primary-color;
          color: white;
      }
  }
}


.breadcumb-banner{
    a{
        font-weight: 600;
    }

    a:hover{
        color: $primary-color-dark !important;
    }
    
    a:focus{
        color: $primary-color !important;
    }

    .breadcrumb{
        background-color:inherit;
        font-weight: 600;
        padding-left: 0px;
        padding-right:0px;
        text-transform: uppercase;
        display:inline;

        li.breadcrumb-item{
            display:inline-block;
        }

        .breadcrumb-item + .breadcrumb-item::before{
            content: '\f0da' !important;
        }
    }
}

.pressside{
    //background-color: #ddd;
    //font-size: small;
    // border: 1px solid #ccc;
    // padding-bottom: 15px;

    .pressside-heading{
        // background-color: $secondary-color-light;
        // padding: 10px;
        color: $white;
        font-weight: bold;
    }

    .pressside-body{
        // padding: 10px;

        ul{
            li{
                // padding: 8px 0px;
               // border-bottom: 1px dashed #333;
            }
        }
    
        .date{
            color: $primary-color;
            font-weight: 600;
            // padding-bottom: 5px;
        }
    
        a{
            text-align: center !important;
            text-transform: capitalize;
        }
        
        a:hover{
            color: $primary-color !important;
            // text-decoration: underline !important; 
        }
    }

    .pressside-footer{
       // padding: 10px;

    }

    
}

.memberside{
    .memberside-body{
        .media{
            // border: 1px solid #ccc;
            // box-shadow: -5px -5px 3px #ccc;
            // padding: 10px 5px;
            // margin: 20px 0px
        }

        h6{
            font-weight: 600;
            color: $primary-color;
        }

        img{
            width: 80px !important;
            max-height: 100px;

            @media only screen and (min-width: 992px) and (max-width: 1130px){
                width: 50px !important;
                max-height: 60px;
            }
        }

        .role{
            color: $secondary-color;
            font-weight: 600;
        }

        p{
            margin-bottom: 5px !important;
        }

        a:hover{
            color: $primary-color !important;
        }
    }
}