#sticky-social {
  right: 20px;
  position: fixed;
  top: 250px;
  z-index: 99999999;

  a {
    background: #333;
    color: #fff;
    display: block;
    height: auto;
    font: 16px "Open Sans", sans-serif;
    position: relative;
    text-align: center;
    width: 40px;
    transition: all .5s ease-in;

    span {
      left: -120px;
      position: absolute;
      text-align: center;
      width: 120px;
      background: #333;
      color: white;
      padding: 15px 10px;
      display: none;
    }

    i{
      padding: 15px 10px;
      font-size: 1.2em;

    }

    // &:hover{
    //   span{
    //     display: block ;
    //   }
    // }

    //
    // &:hover span {
    //   left: 100%;
    // }

    &[class*="facebook"] {
      &:hover, span {
        background: #3b5998;
      }
    }

    &[class*="twitter"] {

      &:hover, span {
        background: #00aced;
      }
    }

    &[class*="gplus"] {
      &:hover, span {
        background: #dd4b39;
      }
    }

    &[class*="linkedin"] {
      &:hover, span {
        background: #007bb6;
      }
    }

    &[class*="instagrem"] {
      &:hover, span {
        background: #517fa4;
      }
    }

    &[class*="stumbleupon"] {
      background: #eb4924;

      &:hover, span {
        background: #eb4924;
      }
    }

    &[class*="pinterest"] {
      background: #cc2127;

      &:hover, span {
        background: #cc2127;
      }
    }

    &[class*="flickr"] {
      background: #ff0084;

      &:hover, span {
        background: #ff0084;
      }
    }

    &[class*="tumblr"] {
      background: #32506d;

      &:hover, span {
        background: #32506d;
      }
    }
  }
}

@media(max-width: 992px){
  #sticky-social{
    display: none;
  }
}
