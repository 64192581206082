
.bg-light-dark {
    background-color: var(--light-dark-color) !important;
  }
  
  .bg-light-dark-faded{
    background-color: rgba(#eee,0.3);
  }
  
  .blink{
    animation: blink 1s ease 0s infinite;
  }
  
  @keyframes blink {
     from {opacity: 0;}
     to {opacity: 1;}
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  //boiler classes
  
  .faded {
    opacity: 0.6;
  }

  .last-no-border-element:last-child{
    .border-element{display:none}
  }
  
  .filter-field {
    .mat-form-field-wrapper {
      padding: 0 !important;
      margin: 0 !important;
    }
    .mat-form-field-outline {
      padding: 0 !important;
    }
  }
  
  .faded-slight {
    opacity: 0.8;
  }
  
  .text-large {
    font-size: 32px;
  }
  
  .text-medium {
    font-size: 24px;
  }
  
  .icon-largest {
    font-size: 48px !important;
    width: 48px !important;
    width: 24px;
    height: 24px;
    transform: scale(2.5);
  }
  
  .bg-primary {
    background-color: var(--primary-color) !important;
  }
  
  .bg-ocean-blue {
    background: #73eaf0 !important;
    color: var(--primary-color) !important;
    &:disabled {
      background: #a8a8a8 !important;
    }
  }
  
  .bg-dark-light{
    background: #d8d8d8 !important;
  }
  
  .bg-secondary {
    background-color: var(--secondary-color) !important;
  }
  
  .text-primary {
    color: var(--primary-color) !important;
  }
  
  .text-secondary {
    color: var(--secondary-color) !important;
  }
  
  .border-dark{
    border: 1px solid rgba(200, 200, 200, 0.3);
  }
  
  .round{
    border-radius: 50% !important;
  }
  
  .rounded-medium {
    border-radius: 20px !important;
  }
  
  .rounded-small {
    border-radius: 15px !important;
  }
  
  .rounded-slight{
      border-radius:0 0 10px 10px !important;
  }

  .rounded-sm-slight{
    @media (min-width: 576px) {
      border-radius: 10px !important;
    }
  }

  .rounded-lg-bottom-right{
    @media (min-width: 992px) {
        border-bottom-right-radius:50px !important;
    }
  }

  .rounded-md-bottom-right{
    @media (min-width: 576px) {
        border-bottom-right-radius:50px !important;
    }
  }

  .rounded-md-bottom-right-slight{
    @media (min-width: 576px) {
        border-bottom-right-radius:20px !important;
    }
  }

 .rounded-md-bottom-left-slight{
    @media (min-width: 576px) {
        border-bottom-left-radius:20px !important;
    }
  }

  .rounded-lg-bottom-left{
    @media (min-width: 992px) {
        border-bottom-left-radius:50px !important;
    }
  }

  .bottom-left{
    bottom:0px;
    left:0px;
  }

  .last-no-pb:last-child{
    padding-bottom: 0 !important;
  }

  
  
  .rounded-slight {
    border-radius: 10px !important;
  }
  
  .rounded-right-slight {
    border-radius: 0 10px 10px 0 !important;
  }
  .rounded-very-slight {
    border-radius:6px !important;
  }
  
  .rounded-right-small {
    border-radius: 0 15px 15px 0 !important;
  }
  
  .rounded-right-medium {
    border-radius: 0 30px 30px 0 !important;
  }
  
  .rounded-top-right-slight {
    border-top-right-radius: 10px !important;
  }
  
  .border-light {
    border: 1px solid rgba(200, 200, 200, 0.1) !important;
  }
  
  .border-primary{
    border: 1px solid var(--primary-color) !important;
  }
  
  .page-animation-height-container {
    height: 70px;
    animation: pull-up-page 0.4s ease 0s forwards;
  }
  
  .shrink-height {
    animation: shrink-height 0.4s ease 0s forwards;
  }
  
  .content-item {
    transform: translateX(100px);
    animation: move-left calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
  }
  
  .text-normal {
    font-weight: normal !important;
  }

  .text-small{
    font-size:13px !important;
  }
  
  .move-up {
    transform: translateY(50px);
    //animation: move-up 0.5s ease 0s forwards;
    animation: move-up calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
  }
  
  .move-down {
    transform: translateY(-50px);
    //animation: move-up 0.5s ease 0s forwards;
    animation: move-down calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
  }
  
  .bounce {
    animation: bounce calc(calc(var(--order) * 200ms) + 100ms) linear 0s infinite;
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(5px);
    }
    75% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .has-hover-bounce:hover{
    --order:4;

    .hover-bounce{
      animation:bounce  calc(calc(var(--order) * 200ms) + 100ms) linear 0s infinite;
    }
  }

  .push-up-on-hover{
    transition: transform 0.3s;
      transform:translateY(0);
      &:hover{
      transform:translateY(-8px);
    }
  }
  
  
  .move-left {
    transform: translateX(100px);
    //animation: move-up 0.5s ease 0s forwards;
    animation: move-left calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
  }
  
  .move-left-undo {
    transform: translateX(0px);
    //animation: move-up 0.5s ease 0s forwards;
    animation: move-left-undo calc(calc(var(--order) * 200ms) + 100ms) ease 0s
      forwards;
  }
  
  .animate {
    transition: all calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
    padding: 0px;
    width: 0px;
    margin: 0px;
    border-radius: 0px;
  }
  
  @keyframes pull-up-page {
    0% {
      height: 70px;
    }
    100% {
      height: 0;
    }
  }
  
  @keyframes shrink-height {
    0% {
      height: 100%;
    }
    100% {
      height: 0;
    }
  }
  
  @keyframes system-content-holder-up-stand-alone {
    0% {
      padding-top: 70px;
    }
    100% {
      padding-top: 3.5em;
    }
  }
  
  @keyframes move-up {
    0% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  @keyframes move-down {
    0% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  @keyframes move-left {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  
  @keyframes move-left-undo {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100px);
    }
  }
  @keyframes expand-width {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
  
  .fade-on {
    animation: fade-on calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
  }
  @keyframes fade-on {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .scale-up {
    animation: scale-up calc(calc(var(--order) * 200ms) + 100ms) ease 0s forwards;
  }
  @keyframes scale-up {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  //normalize
  .system-layout {
    .content {
      border-radius: 0px !important;
  
      @media (min-width: 992px) {
        border-radius: 10px 10px 0px 0px !important;
      }
    }
  }
  
  .icon-button {
    .mat-icon,
    .icon {
      position: relative;
      top: 5px;
    }
  }
  
  .top-left{
    top:0;
    left:0;
  }
  
  .pr-sm-0{
    @media (min-width: 576px) {
      padding-right: 0 !important;
    }
  }
  
  .pl-sm-0{
    @media (min-width: 576px) {
      padding-left: 0 !important;
    }
  }
  
  .pr-sm-0{
    @media (min-width: 576px) {
      padding-right: 2 !important;
    }
  }
  
  .pl-sm-0{
    @media (min-width: 576px) {
      padding-left: 0 !important;
    }
  }
  
  
  .position-lg-absolute{
    @media (min-width: 992px) {
      position:absolute !important;
    }
  }
  
  .position-md-absolute{
    @media (min-width:  768px) {
      position:absolute !important;
    }
  }
  
  
  .position-sm-absolute{
    @media (min-width: 576px) {
      position:absolute !important;
    }
  }
  
  .indexed-list-item {
    border-top-left-radius: 0px;
    max-width: 680px;
  }
  
  .indexed-list-item.w-100 {
    max-width: 100%;
  }
  
  .indexed-list {
    margin-left: 20px !important;
  }
  
  .list-index {
    position: absolute;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: -20px;
    top: 0;
    border: 5px solid var(--light-dark-color);
  }
  
  .text-bold {
    font-weight: 500;
  }
  
  .top--5{
    position: relative;
    top:-5px;
    @media print {
      top:0px;
    }
  }
  
  .top--4{
    position: relative;
    top:-4px;
    @media print {
      top:0px;
    }
  }
  
  .top--3{
    position: relative;
    top:-3px;
    @media print {
      top:0px;
    }
  }
  
  
  .top--2{
    position: relative;
    top:-2px;
    @media print {
      top:0px;
    }
  }
  
  .top--1{
    position: relative;
    top:-1px;
    @media print {
      top:0px;
    }
  }
  

  .bg-radial-gradient-transparent-white{
    background: radial-gradient(rgba($white,0.5) 0%, rgba($white,1) 70%);
  }

  .bg-radial-gradient-transparent-white-inverted{
    background: radial-gradient(rgba($white,0.9) 0%, rgba($white,0) 90%);
  }


  .text-shadow{
    text-shadow: 1px 1px 2px rgba($primary-color, 1),2px 2px 8px rgba(#000, 0.6),4px 4px 8px rgba(#000, 0.4),8px 8px 16px rgba(#000, 0.3); 
  }

  .text-shadow-light{
    text-shadow: 1px 1px 2px rgba($white, 1),1.5px 1.5px 3px rgba(#000, 0.4),; 
  }

  

  
// .bg-gradient-faded {
//     background: linear-gradient(
//       to right,
//       rgba(145, 152, 229, 0.2),
//       rgba(230, 100, 101, 0.2)
//     ) !important;
//   }
  
//   .bg-gradient-slightly-faded { 
//     background: linear-gradient(
//       to right,
//       rgba(145, 152, 229, 0.05),
//       rgba(230, 100, 101, 0.05)
//     ) !important;
//   }
  
//   .border-slightly-faded{
//     border:1px solid rgba(100, 100, 100, 0.02) !important;
//   }
  
//   .bg-primary-slightly-faded {
//     background: linear-gradient(
//       to right,
//       rgba(145, 152, 229, 0.05),
//       rgba(145, 152, 229, 0.04)
//     ) !important;
//   }
  
  // .bg-primary-faded{
  //   background: linear-gradient(
  //     to right,
  //     rgba(145, 152, 229, 0.1),
  //     rgba(145, 152, 229, 0.09)
  //   ) !important;
  // }
  
  .bg-primary-pale{
    background-color: #415463;
  }
  
  .bg-primary-light{
    background-color: #d4d9dd;
    background-color:  var(--light-dark-color) 
  }
  
  .bg-hover-faded-gradient {
    background: white !important;
    transition: all 0.3s;
  
    &:hover {
      background: linear-gradient(
        to right,
        rgba(234, 236, 255, 1),
        rgba(252, 239, 239, 1)
      ) !important;
    }
  }
  
  .moderate-wide {
    max-width: 820px;
  }
  
  .width-moderate {
    max-width: 820px;
  }
  
  .bg-gradient-very-faded {
    background: linear-gradient(
      to right,
      rgba(145, 152, 229, 0.1),
      rgba(230, 100, 101, 0.1)
    ) !important;
  }
  
  .custom-list .mat-list-item-content {
    flex-direction: column !important;
  }
  
  .box-shadow {
    -webkit-box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 13px 16px 0px rgba(0, 0, 0, 0.08);
  }
  .box-shadow-slight {
    //@at-root box-shadow: 0 3px 8px #d4d4d4;
    -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08) !important;
    -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08) !important;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08) !important;
  }
  
  .box-shadow-faded {
    //@at-root box-shadow: 0 3px 8px #d4d4d4;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05) !important;
    -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05) !important;
  }
  
  .white-box {
    background: white;
    min-height: 80px;
    padding: 0px;
    box-shadow: 0px 2px 3px #dedede;
    border-radius: 10px;
  }

  .stroke-primary{fill:none;stroke:var(--primary-color);stroke-miterlimit:10;stroke-width:4px;}
  .stroke-secondary{fill:none;stroke:var(--secondary-color);stroke-miterlimit:10;stroke-width:4px;}
  .stroke-white{fill:none;stroke:$white;stroke-miterlimit:10;stroke-width:4px;}

  .fill-primary{fill:var(--primary-color);}
  .fill-secondary{fill:var(--secondary-color);}
  .fill-white{fill:$white;}

  .fill-primary-faded{fill:rgba($primary-color, 0.5);}
  .fill-secondary-faded{fill:rgba($secondary-color, 0.5);}
  .fill-white-faded{fill:rgba($white, 0.5);}

  .white-icon{
    filter: brightness(0) invert(1);;
  }

  .rendering-crisp{ 
    image-rendering: crisp-edges !important;
  }
  .rendering-pixelated{ 
    image-rendering: pixelated !important;
  }

  .has-full-height-on-hover{
    .full-height-on-hover{
      transition:all 0.5s;
      // transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      height:0%;
      padding:0 2em;
    }
    &:hover .full-height-on-hover{
      height:100%;
      padding:2e 2em;
     
    }
  }

  .has-expand-height-on-hover{
    .expand-height-on-hover, .expand-height-on-hover-50, .expand-height-on-hover-75{
      transition:all 0.5s;
      // transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      height:0%;
      padding:0 2em;
      // opacity: 0;
    }
    &:hover{
      .expand-height-on-hover{height:100%;padding:2em 2em; }
      .expand-height-on-hover-50{height:50%;padding:1.5em 2em; }
      .expand-height-on-hover-75{height:75%;padding:2em 2em; }

      .expand-height-on-hover, .expand-height-on-hover-50, .expand-height-on-hover-75{
        // opacity: 1;
      }
    }
  }

  .has-to-scale-on-hover{
    .to-scale-on-hover{
      transition:all 0.5s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
       transform: scale(1.2);
    }

    &:hover{
      .to-scale-on-hover{transform: scale(1)}
    }
    
  }

  .full-opacity-on-hover{
    opacity: 0.7;
    transition:all 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    &:hover { opacity:1}
  }


  .slide-up-on-hover{
    transition:all 0.4s;
    transform: translateY(0px);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover{
      transform: translateY(-5px);

    }
  }

  .shrink-on-hover{
    transition:all 0.35s;
    transform: scale(1);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &:hover{
      transform: scale(0.95);
    }
  }

  .has-hover-shrinked-image{
    .hover-shrinked-image-50,.hover-shrinked-image-80,.hover-shrinked-image{
      transition:all 0.5s;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      // transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transform: scale(1);
    }
    &:hover {
      .hover-shrinked-image{transform: scale(0.9);}
      .hover-shrinked-image-80{transform: scale(0.8);}
      .hover-shrinked-image-50{transform: scale(0.5);}
      .hover-shrinked-image-50{transform: scale(0.5);}
    } 
  }

  .has-blur-on-hover{
    .blur-on-hover{
      transition:all 0.7s;
      // transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      backdrop-filter: blur(0px);
    }
    &:hover .blur-on-hover{
      backdrop-filter: blur(5px)
    }
  }

  .background-image{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .box-30{
    width:30px; height:30px
  }

  .box-35{
    width:35px; height:35px
  }


   .box-40{
    width:40px; height:40px
  }


  .box-60{
    width:60px; height:60px
  }


  .box-70{
    width:70px; height:70px
  }

  .box-80{
    width:80px; height:80px
  }

  .box-90{
    width:90px; height:90px
  }

  .box-100{
    width:100px; height:100px
  }

  .box-110{
    width:110px; height:110px
  }

  .box-115{
    width:115px; height:115px
  }


  .ratio-102{
    width:102%;
    height:102%
  }

  .ratio-105{
    width:105%;
    height:105%
  }
  
  .box-detail {
    border-bottom: 1px solid #dadada;
    padding-bottom: 5px;
    margin-bottom: 20px;
    min-height: 46px;
    > div:first-child {
      color: var(--primary-color);
      font-weight: 500;
    }
    > div:last-child {
      color: #666;
    }
  }
  
  .hover-shadow-medium {
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 4px 10px #d4d4d4;
    }
  }
  
  .width-medium {
    max-width: 680px;
    margin: auto;
  }
  
  .progress-body {
    position: relative;
    width: 90%;
    position: relative;
    margin: auto;
    cursor: pointer;
  
    .media {
      justify-content: center;
    }
  
    &::before {
      content: "";
      display: block;
      height: 4px;
      width: 90%;
      margin: auto;
      z-index: 1;
      border-radius: 2px;
      background-color: #eeeeee;
      position: absolute;
      bottom: 5px;
    }
  
    .progress {
      display: block;
      height: 4px;
      border-radius: 2px;
      position: absolute;
      bottom: 5px;
      z-index: 2;
      left: 0;
      overflow: hidden;
  
      div {
        height: 100%;
        float: left;
        position: relative;
        z-index: 3;
        width:100%;
        @media screen {
          width: 0%;
          animation: progressAnimation 4s;
          animation-delay: 0.3s;
          animation-direction: alternate;
          animation-fill-mode: forwards;
        }
       
      }
    }
  }
  
  .large-icon {
    font-size: 48px !important;
    width: 48px !important;
    width: 24px;
    height: 24px;
    transform: scale(2);
  }
  
  .icon-medium {
    font-size: 48px !important;
    width: 48px !important;
    width: 24px;
    height: 24px;
    transform: scale(1);
  }
  
  @keyframes progressAnimation {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  
  .w-5 {
    width: 5%;
  }
  .w-10 {
    width: 10%;
  }
  .w-15 {
    width: 15%;
  }
  .w-20 {
    width: 20%;
  }
  .w-25 {
    width: 25%;
  }
  .w-30 {
    width: 30%;
  }
  .w-35 {
    width: 35%;
  }
  .w-40 {
    width: 40%;
  }
  .w-45 {
    width: 45%;
  }
  .w-50 {
    width: 50%;
  }
  .w-55 {
    width: 55%;
  }
  .w-60 {
    width: 60%;
  }
  .w-65 {
    width: 65%;
  }
  .w-70 {
    width: 70%;
  }
  .w-75 {
    width: 75%;
  }
  .w-80 {
    width: 80%;
  }
  .w-85 {
    width: 85%;
  }
  .w-90 {
    width: 90%;
  }
  .w-95 {
    width: 95%;
  }
  .w-100 {
    width: 100%;
  }
  
  .bg-none {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  
  .bg-white-faded {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }

  .bg-white-slightly-faded {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  
  .square-container{
    width:100%;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
    *>{
      position: absolute;
    }
  
    &::before{
      content: "";
      padding-bottom: 100%;
      display: block;
      position: relative;
      width: 100%;
      height:0;
    }
  }
  
  .min-width-sm-680 {
    @media (min-width: 576px) {
      min-width: 680px;
    }
  }
  
  .min-width-sm-820 {
    @media (min-width: 576px) {
      min-width: 820;
    }
  }

  .min-width-sm-200 {
    @media (min-width: 576px) {
      min-width: 200px;
    }
  }

  .max-width-200 {
      max-width: 200px;
  }

  .max-width-320 {
    max-width: 320px;
    margin: auto;
  }


  .z-index-2{
      z-index: 2;
  }

  .min-height-lg-60{
    @media (min-width: 992px) {
      min-height: 60px;
    }
  }

  // rounded-bottom-right-md

  .min-height-md-60{
    @media (min-width: 576px) {
      min-height: 60px;
    }
  }

  .min-height-lg-80{
    @media (min-width: 992px) {
      min-height: 80px;
    }
  }

  .min-height-md-80{
    @media (min-width: 576px) {
      min-height: 80px;
    }
  }

  .min-height-md-180{
    @media (min-width: 576px) {
      min-height: 180px; 
    }
  }

  .min-height-md-120{
    @media (min-width: 576px) {
      min-height: 120px;
    }
  }

  .min-height-md-240{
    @media (min-width: 576px) {
      min-height: 240px;
    }
  }

  .text-hover-primary{
    transition: all 0.3s;
    &:hover {
      color:$primary-color !important
    }
  }

   .text-hover-secondary{
    transition: all 0.3s;
    &:hover {
      color:$secondary-color !important
    }
  }
  
  
 .min-height-150{
    min-height:150px;
 }

 .min-height-180{
       min-height:180px;
 }

 .min-height-200{
     min-height:200px;
 }

 .min-height-320{
  min-height:320px;
}

.min-height-280{
  min-height:280px;
}

.min-height-250{
  min-height:250px;
}


  .max-height-40{
     max-height:40px;
  }

  .max-height-60{
    max-height:60px;
  }

  .max-height-80{
    max-height:80px;
  }
  
  .max-width-720 {
    margin: auto;
    max-width: 720px;
  }
  
  .max-width-680 {
    margin: auto;
    max-width: 680px;
  }
  
  .max-width-540 {
    margin: auto;
    max-width: 540px;
  }
  
  .max-width-240 {
    margin: auto;
    max-width: 240px;
  }
  
  .max-width-210{
    margin: auto;
    max-width: 210px;
  }
  
  .max-width-180{
    margin: auto;
    max-width: 180px;
  }
  
  .max-width-256 {
    margin: auto;
    max-width: 256px;
  }
  
  .max-width-360 {
    margin: auto;
    max-width: 360px;
  }
  
  .max-width-420 {
    margin: auto;
    max-width: 420px;
  }
  
  .max-width-480 {
    margin: auto;
    max-width: 480px;
  }
  
  .hover-bg-fill {
    position: relative;
    .bg-fill {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      border-radius: inherit;
      transition: height 0.3s;
      z-index: 0;
    }
  
    .bg-fill.height-40 {
      height: 40%;
    }
  
    .content {
      position: relative;
      z-index: 1;
    }
  
    &:hover {
      .bg-fill {
        height: 100%;
        border-radius: inherit;
      }
    }
  }

  .bg-transparent-white{
    background:rgba(#fff,0.6);
  }

  .bg-white-transparent{
    background:rgba(#fff,0.6);
  }

  
  .bg-white-transparent-hover-secondary{
    background:rgba(#fff,0.6);
    transition: background 0.4s;
    &:hover{background:rgba($secondary-color,0.6);}
  }

  .show-details-on-hover{
    transition: all 0.3s !important;
    transform: translateY(0);

    .unround-bottom-on-hover{
      transition: border-radius 0.2s;
    }

    .details{
      transition: opacity 0.35s,transform 0.35s;
      opacity: 0;
      transform: translateY(12px);
    }

    &:hover{
      transform: translateY(-6px);

      .unround-bottom-on-hover{
        border-bottom-left-radius:0 !important;
        border-bottom-right-radius:0 !important;
      }
  
      .details{
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .has-hover-hide-show{
     .hover-show{ display:none !important}
     .hover-hide{display:unset !important}
  
     &:hover{
      .hover-show{ display:unset !important}
      .hover-hide{display:none !important}
     }
  }
  
  .has-hover-hide-show-stacked{
    .stack{
      display:flex;
      justify-content:center;
      align-items: center;
    }
    .hover-hide,.hover-show{
      position: absolute;
      transition:transform 0.3s, opacity 0.3s
    }
    .hover-show{
      position: relative;
    }
  
    .hover-show{ opacity:0 ; transform: scale(0.7);}
    .hover-hide{opacity: 1; transform: scale(1);}
  
    &:hover{
     .hover-show{ opacity: 1; transform: scale(1);}
     .hover-hide{opacity: 0; transform: scale(0.7);}
    }
  }
  
 
  @keyframes fade-on-out {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes slight-slide-in {
    0% {
      transform: translateX(10px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  
  .rounded-0{
    border-radius: 0px !important;
  }

  .has-hover-text-primary{
    .hover-text-primary {color:inherit;}
    transition:color 0.3s;
    &:hover{
      .hover-text-primary{
        color:$primary-color;
      }
    }
  }

  .has-hover-text-secondary{
    .hover-text-secondary {color:inherit;}
    transition:color 0.3s;
    &:hover{
      .hover-text-secondary{
        color:$secondary-color;
      }
    }
  }


  .last-no-border:last-child{
    border:unset !important;
  }
  
  .hover-icon-left,.hover-icon-right{
    .icon{
      position: relative;
      transition: left 0.3s;
      left: 0;
    }
  }

  .hover-icon-right:hover{
    .icon{left: 8px;}
  }

  .hover-icon-left:hover{
    .icon{left: -8px;}
  }