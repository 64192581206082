.side-bar {
    a{
     color: #333;
    }

  .nav-tabs {
    border-bottom: none;

    .nav-link {
      color: #aeaeae;
      border: none;
      transition: all .5s ease;

      &.active,
      &:hover {
        border-bottom: 2px solid $secondary-color;
        color: #222;
        background: transparent;
      }
    }

  }
  .sidebar-tabing .media {
    margin-top: 20px;
  }

  .sidebar-tabing img {
    width: 60px;
    //height: 50px;
  }

  .sidebar-tabing .title-small {
    line-height: 23px;
    margin-top: 5px;
    font-size: .9em;

    a{
      color: #333;

      &:hover{
        color: $secondary-color;
      }
    }
  }

  .aside-title{
    border-bottom: 2px solid $secondary-color;
    color: #222;
    font-size: 1.1em;
    line-height: 2;
  }

}
