$primary-color: #2a5caa;
$primary-color-dark: darken($primary-color, 5%);
$primary-color-light: lighten($primary-color, 0%);
$primary-text-color: #212121;

$dark-color:#4b4b4b;
$darker-color:#272727;

$secondary-color: #8dc63f;
$secondary-color-dark: darken($secondary-color, 5%);
$secondary-color-light: lighten($secondary-color, 0%);
$secondary-text-color: #212121;

$accent-color: #696b6d;
$divider-color: #BDBDBD; 

:root{
    --dark-color:#2e2e2e;
    --darker-color:#272727;
    --primary-color:#2a5caa;
    --secondary-color:#8dc63f;
    --order:1.5;
}

.bg-gradient{
    background: linear-gradient(
    to top,
    rgba($primary-color, 1),
    rgba($dark-color, 1)
    ) !important;
}

.bg-gradient-with-transparent{
  background: linear-gradient(
    to top,
    rgba($primary-color, 1) 0%,
    rgba($dark-color, 0.95) 80%,
    rgba($dark-color, 0.8) 100%,
    ) !important;
}

.bg-gradient-faded {
    background: linear-gradient(
      to top,
      rgba($primary-color, 0.6),
      rgba($dark-color, 0.6)
    ) !important;
  }

  .bg-gradient-primary-white{
    background: linear-gradient(
      to bottom,
      rgba($dark-color, 0.6),
      rgba($white, 1)
    ) !important;
  }

  
  .bg-gradient-secondary-white{
    background: linear-gradient(
      to bottom,
      rgba($secondary-color, 0.6),
      rgba($white, 1)
    ) !important;
  }

   
  .bg-gradient-secondary-transparent{
    background: linear-gradient(
      to bottom,
      rgba($secondary-color, 0.6),
      rgba($white, 0)
    ) !important;
  }

    .bg-gradient-primary-transparent{
    background: linear-gradient(
      to bottom,
      rgba($primary-color, 0.6),
      rgba($white, 0)
    ) !important;
  }



  .bg-gradient-transparent-white{
    background: linear-gradient(
      to bottom,
      rgba($white, 0.0),
      rgba($white, 1)
    ) !important;
  }

  .bg-gradient-transparent-white-faded{
    background: linear-gradient(
      to bottom,
      rgba($white, 0.3),
      rgba($white, 1)
    ) !important;
  }

  .bg-primary-gradient-faded {
    background: linear-gradient(
      to top,
      rgba($primary-color, 0.6),
      rgba($white, 0)
    ) !important;
  }

  .bg-white-gradient {
    background: linear-gradient(
      to top,
      rgba($white, 1),
      rgba($white, 0)
    ) !important;
  }
  
  .bg-gradient-slightly-faded {
    background: linear-gradient(
      to top,    
      rgba(0, 157, 15, 0.05),
      rgba(19, 34, 14, 0.05)
    ) !important;
}


//social Media Buttons
$facebook: #3b5998;
$twitter: #00aced;
$google-plus: #dd4b39;
$google: #dd4b39;
$linkedin: #007bb6;
$youtube: #bb0000;
$instagram: #517fa4;
$pinterest: #cb2027;
$flickr: #ff0084;
$tumblr: #32506d;
$forusquare: #0072b1;
$dribbble: #ea4c89;
$vine: #00bf8f;
$rss: #ff6f00;
$blogger: #fb8f3d;

// Box Shadow
$box-shadow0: 0 2px 2px 0 rgba(0, 0, 0, .16), 0 0 0 1px rgba(0, 0, 0, .08);
$box-shadow1: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
$box-shadow2: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
$box-shadow3: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
$box-shadow-hover: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
$box-shadow-btn: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
$box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
$box-shadow-card: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
$text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
$bg-linear-gradient: linear-gradient(to bottom, #a0c9ff 0, #d8e9ff 40%, #679ae9 100%);


