
/******************  News Slider Demo-1 *******************/

.post-slide {
  overflow: hidden;
  margin-right: 15px;
  background-color: #fff !important;

  .post-img {
    float: left;
    width: 50%;
    position: relative;
    margin-right: 30px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .post-date {
    background: #ec3c6a;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 2% 3%;
    width: 60px;
    height: 60px;
    text-align: center;
    transition: all 0.50s ease;
  }

  .date {
    display: block;
    font-size: 20px;
    font-weight: 700;
  }

  .month {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
  }

  .post-review {
    padding: 5% 3% 1% 0;
    border-top: 3px solid #38cfd8;
  }

  &:hover .post-review {
    border-top-color: #ec3c6a;
  }

  .post-title {
    margin: 0 0 10px 0;

    a {
      font-size: 14px;
      color: #333;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }

  .post-bar {
    padding: 0;
    list-style: none;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 20px;

    &:after {
      border: 1px solid #38cfd8;
      bottom: -10px;
      content: "";
      display: block;
      position: absolute;
      right: 36%;
      width: 25px;
    }

    &:before {
      border: 1px solid #38cfd8;
      bottom: -10px;
      content: "";
      display: block;
      position: absolute;
      right: 36%;
      width: 25px;
      border: 1px solid #ec3c6a;
      right: 32%;
    }

    li {
      color: #555;
      font-size: 10px;
      margin-right: 10px;
      display: inline-block;

      a {
        font-size: 13px;
        text-decoration: none;
        text-transform: uppercase;
        color: #ec3c6a;

        &:hover {
          color: #ec3c6a;
        }
      }

      i {
        color: #777;
        margin-right: 5px;
      }
    }
  }

  .post-description {
    font-size: 12px;
    line-height: 21px;
    color: #444454;
  }
}

.owl-theme .owl-controls {
  margin-top: 30px;

  .owl-page {
    span {
      background: #fff;
      border: 2px solid #37a6a4;
    }

    &.active span {
      background: #37a6a4;
    }
  }

  &.clickable .owl-page:hover span {
    background: #37a6a4;
  }
}

@media only screen and (max-width: 990px) {
  .post-slide {
    .post-img {
      width: 100%;
    }

    .post-review {
      width: 100%;
      border-bottom: 4px solid #ec3c6a;
    }

    .post-bar {
      &:before {
        left: 0;
      }

      &:after {
        left: 25px;
      }
    }
  }
}

/******************  News Slider Demo-2 *******************/


.post-slide2 {
  margin: 0 15px;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      transform: scale(1);
      transition: all 1s ease-in-out 0s;
    }
  }

  &:hover .post-img img {
    transform: scale(1.08);
  }

  .post-content {
    background: #fff;
    padding: 20px;
  }

  .post-title {
    font-size: 17px;
    font-weight: 600;
    margin-top: 0;
    text-transform: capitalize;

    a {
      display: inline-block;
      color: #808080;
      transition: all 0.3s ease 0s;

      &:hover {
        color: #3d3030;
        text-decoration: none;
      }
    }
  }

  .post-description {
    font-size: 15px;
    color: #676767;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .post-bar {
    padding: 0;
    margin-bottom: 15px;
    list-style: none;

    li {
      color: #676767;
      padding: 2px 0;

      i {
        margin-right: 5px;
      }

      a {
        display: inline-block;
        font-size: 12px;
        color: #808080;
        transition: all 0.3s ease 0s;

        &:after {
          content: ",";
        }

        &:last-child:after {
          content: "";
        }

        &:hover {
          color: #3d3030;
          text-decoration: none;
        }
      }
    }
  }

  .read-more {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: #e7989a;
    border-bottom-right-radius: 10px;
    text-transform: capitalize;
    transition: all 0.30s linear;

    &:hover {
      background: #333;
      text-decoration: none;
    }
  }
}

/******************  News Slider Demo-3 *******************/

.post-slide3 {
  margin: 0 15px;
  padding: 0 25px 20px 25px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    position: relative;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .post-icon {
    width: 60px;
    height: 60px;
    display: block;
    position: absolute;
    bottom: 25px;
    left: 25px;
    text-align: center;
    background: #333;
    opacity: 0;
    border-radius: 3px;
    transition: all 300ms ease-in-out 0s;
  }

  &:hover .post-icon {
    opacity: 1;
  }

  .post-icon i {
    font-size: 20px;
    color: #fff;
    line-height: 60px;
  }

  .post-bar {
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: uppercase;

    li {
      display: inline-block;
      font-size: 15px;
      color: #676767;
      margin-right: 5px;

      &:after {
        content: "/";
        margin-left: 5px;
      }

      &:last-child:after {
        content: "";
      }

      a {
        color: #8e44ad;
        transition: all 0.3s ease 0s;

        &:hover {
          color: #333;
          text-decoration: none;
        }

        &:after {
          content: ",";
          margin: 0 5px;
        }

        &:last-child:after {
          content: "";
        }
      }
    }
  }

  .post-title {
    margin: 15px 0;
    text-transform: capitalize;

    a {
      font-size: 22px;
      font-weight: 600;
      color: #3c3c3c;
      transition: all 300ms linear 0ms;

      &:hover {
        color: #8e44ad;
        text-decoration: none;
      }
    }
  }

  .post-description {
    font-size: 16px;
    color: #676767;
    line-height: 24px;
    padding-bottom: 15px;
  }

  .read-more {
    display: inline-block;

    &:hover {
      text-decoration: none;
    }

    i {
      font-size: 19px;
      color: #333;
      margin-right: 5px;
      transition: all 0.40s linear 0s;
    }

    span {
      font-size: 16px;
      color: #333;
      opacity: 0;
      text-transform: uppercase;
      transition: all 0.40s linear 0s;
    }

    &:after {
      content: "";
      display: block;
      width: 40%;
      position: relative;
      bottom: -20px;
      border-bottom: 3px solid #333;
      opacity: 0;
      transition: all 0.40s linear 0s;
    }
  }

  &:hover .read-more {
    span, &:after {
      opacity: 1;
    }
  }

  .read-more:hover {
    span, i, &:after {
      color: #8e44ad;
      border-bottom-color: #8e44ad;
    }
  }
}

/******************  News Slider Demo-4 *******************/

.post-slide4 {
  margin: 0 10px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-info {
    padding: 5px 10px;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 5px;

      i {
        margin-right: 8px;
      }

      a {
        font-size: 11px;
        font-weight: bold;
        color: #7e828a;
        text-transform: uppercase;

        &:hover {
          color: #1dcfd1;
          text-decoration: none;
        }
      }
    }
  }

  .post-img {
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
      transition: opacity 0.40s linear 0s;
    }
  }

  &:hover .post-img:before {
    opacity: 1;
  }

  .post-img img {
    width: 100%;
    height: auto;
  }

  .read {
    position: absolute;
    bottom: 30px;
    left: 50px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    opacity: 0;
    transition: all 0.40s linear 0s;
  }

  &:hover .read {
    opacity: 1;
  }

  .read:hover {
    text-decoration: none;
    color: #1dcfd1;
  }

  .post-content {
    padding: 40px 15px;
    position: relative;
  }

  .post-author {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: 10px;
    overflow: hidden;
    border: 4px solid #fff;

    img {
      width: 100%;
      height: auto;
    }
  }

  .post-title {
    font-size: 14px;
    font-weight: bold;
    color: #1dcfd1;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    transition: all 0.30s linear 0s;

    &:after {
      content: "";
      width: 25px;
      display: block;
      margin-top: 10px;
      border-bottom: 4px solid #333;
    }
  }

  .post-description {
    font-size: 13px;
    color: #555;
    margin-bottom: 20px;
  }
}

/******************  News Slider Demo-5 *******************/

.post-slide5 {
  margin: 0 15px;
  transition: all 0.4s ease-in-out 0s;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0);
      transition: all 0.4s linear 0s;
    }
  }

  &:hover .post-img:before {
    background: rgba(0, 0, 0, 0.6);
  }

  .post-img img {
    width: 100%;
    height: auto;
  }

  .category {
    width: 20%;
    font-size: 16px;
    color: #fff;
    line-height: 11px;
    text-align: center;
    text-transform: capitalize;
    padding: 11px 0;
    background: #ff9412;
    position: absolute;
    bottom: 0;
    left: -50%;
    transition: all 0.5s ease-in-out 0s;
  }

  &:hover .category {
    left: 0;
  }

  .post-review {
    padding: 25px 20px;
    background: #fff;
    position: relative;
  }

  .post-title {
    margin: 0;

    a {
      display: inline-block;
      font-size: 16px;
      color: #ff9412;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 25px;
      transition: all 0.30s linear 0s;

      &:hover {
        text-decoration: none;
        color: #555;
      }
    }
  }

  .post-description {
    font-size: 15px;
    color: #555;
    line-height: 26px;
  }
}

.post-review .post-bar {
  margin-top: 20px;
}

.post-bar span {
  display: inline-block;
  font-size: 14px;

  i {
    margin-right: 5px;
    color: #999;
  }

  a {
    color: #999;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
      color: #ff9412;
    }
  }

  &.comments {
    float: right;
  }
}

@media only screen and (max-width: 359px) {
  .post-slide5 .category {
    font-size: 13px;
  }
}

/******************  News Slider Demo-6 *******************/

.post-slide6 {
  margin: 0 10px;
  border-left: 8px solid #1dcfd1;
  border-bottom: 8px solid #1dcfd1;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0);
      transition: all 0.40s linear 0s;
    }
  }

  &:hover .post-img:before {
    background: rgba(0, 0, 0, 0.6);
  }

  .post-img img {
    width: 100%;
    height: auto;
  }

  .post-info {
    width: 75%;
    position: absolute;
    bottom: -100%;
    left: 12.5%;
    background: #1dcfd1;
    text-align: center;
    line-height: 26px;
    padding: 15px;
    transition: bottom 0.40s ease-in-out 0s;
  }

  &:hover .post-info {
    bottom: 0;
  }

  .category {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      font-size: 16px;
      color: #fff;
      text-transform: capitalize;
    }
  }

  .post-date {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
  }

  .category li {
    &:after {
      content: " /";
    }

    &:last-child:after {
      content: "";
    }

    a {
      color: #fff;
      transition: all 0.40s linear;

      &:hover {
        color: #555;
        text-decoration: none;
      }
    }
  }

  .post-review {
    padding: 35px 20px 25px;
    background: #fff;
    position: relative;
  }

  .icons {
    width: 90px;
    height: 90px;
    border: 4px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    right: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .post-title {
    margin: 0 0 25px 0;

    a {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #1dcfd1;
      display: inline-block;
      text-transform: uppercase;
      transition: all 0.30s linear 0s;

      &:hover {
        text-decoration: none;
        color: #555;
      }
    }
  }

  .post-description {
    color: #555;
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .read {
    font-size: 13px;
    color: #555;
    display: block;
    text-align: right;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
      color: #1dcfd1;
    }
  }
}

/******************  News Slider Demo-7 *******************/

.post-slide7 {
  padding: 0 10px;
  transform: translateY(0);
  transition: all 0.30s ease 0s;

  &:hover {
    transform: translateY(-10px);
  }

  .post-img {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background-image: linear-gradient(to left, #c9cd19, #5fc55a, #00b18d, #0096a7, #0e789d);
      // background: linear-gradient(to left, rgba(210, 130, 19, 0.7), rgba(170, 55, 114, 0.7));
      // transform: translateY(-100%);
      transition: all 0.70s ease 0s;
    }
  }

  &:hover .post-img:after {
    transform: translateY(0);
    opacity: 0.3;
  }

  .post-img:before {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 20%;
    font-size: 30px;
    color: #fff;
    transition: all 0.50s ease 0s;
    z-index: 1;
  }

  &:hover .post-img:before {
    // content: url('../images/emblem.png');
    top: 20%;
  }

  .icons {
    position: absolute;
    bottom: -16px;
    left: 30px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
    }
  }

  .post-review {
    border-bottom: 3px solid $secondary-color;
    // border-left: 1px solid;
    // border-right: 1px solid;
    border-top: none;
    padding: 5px 20px 25px;
    background: #fff;
    position: relative;

    &:after {
      content: "";
      width: 90%;
      height: 10px;
      position: absolute;
      top: 100%;
      left: 5%;
      opacity: 0;
      background: rgba(0, 0, 0, 0) radial-gradient(ellipse at center center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
      transform: translateY(0);
      transition: all 0.30s ease 0s;
    }
  }

  &:hover .post-review:after {
    opacity: 1;
    transform: translateY(5px);
  }

  .post-bar {
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      font-size: 16px;
      font-family: serif,Arial;
      color: #555;
      margin-right: 10px;
      text-transform: capitalize;

      i {
        color: $primary-color;
        margin-right: 8px;
      }
    }
  }

  .post-title {
    margin: 0 0 20px 0;
    color: #555;
    font-weight: bold;
    font-size: 18px;
  }

  .post-description {
    font-size: 15px;
    line-height: 21px;
    color: #808080;
  }

  .read {
    text-transform: capitalize;
    font-size: 15px;
    color: #9c4a6c;

    i {
      margin-left: 10px;
    }

    &:hover {
      text-decoration: none;
      color: #333;
    }
  }
}

/******************  News Slider Demo-8 *******************/

.post-slide8 {
  margin: 0 15px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .over-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.30s ease;
  }

  &:hover .over-layer {
    opacity: 1;
  }

  .post-link {
    margin: 0;
    padding: 0;
    position: relative;
    top: 45%;
    text-align: center;

    li {
      display: inline-block;
      list-style: none;
      margin-right: 20px;

      a {
        color: #fff;
        font-size: 20px;

        &:hover {
          color: #ff8b3d;
          text-decoration: none;
        }
      }
    }
  }

  .post-date {
    position: absolute;
    top: 10%;
    left: 4%;
  }

  .date {
    display: inline-block;
    border-radius: 3px 0 0 3px;
    padding: 5px 10px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    background: #333;
    float: left;
  }

  .month {
    display: inline-block;
    border-radius: 0 3px 3px 0;
    padding: 5px 13px;
    color: #111;
    font-size: 20px;
    font-weight: bold;
    background: #ff8b3d;
  }

  .post-content {
    padding: 30px;
  }

  .post-title {
    margin: 0 0 15px 0;

    a {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      display: inline-block;
      text-transform: capitalize;
      transition: all 0.3s ease 0s;

      &:hover {
        text-decoration: none;
        color: #ff8b3d;
      }
    }
  }

  .post-description {
    font-size: 14px;
    line-height: 24px;
    color: #808080;
  }

  .read-more {
    color: #333;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    transition: color 0.20s linear;

    &:hover {
      text-decoration: none;
      color: #ff8b3d;
    }

    &:after {
      content: "";
      position: absolute;
      width: 30%;
      display: block;
      border: 1px solid #ff8b3d;
      transition: all 0.30s ease;
    }

    &:hover:after {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 479px) {
  .post-slide8 {
    .month, .date {
      font-size: 14px;
    }
  }
}

/******************  News Slider Demo-9 *******************/

.post-slide9 {
  margin: 0 10px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: auto;
      transform: scale(1, 1);
      transition: all 0.30s ease 0s;
    }
  }

  &:hover .post-img img {
    transform: scale(1.2, 1.2);
  }

  .over-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    text-align: center;
    background: rgba(68, 67, 64, 0.9);
    transition: all 0.50s linear;
  }

  &:hover .over-layer {
    opacity: 1;
  }

  .post-link {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    top: 45%;

    li {
      display: inline-block;
      margin-right: 10px;

      a {
        width: 60px;
        height: 60px;
        line-height: 59px;
        border-radius: 50%;
        color: #fff;
        background: #333;
        font-size: 20px;
        transform: scale(1, 1);
        transition: all 0.20s linear;

        &:hover {
          text-decoration: none;
          transform: scale(1.1, 1.1);
        }
      }
    }
  }

  .post-review {
    padding: 15px 0;
    overflow: hidden;
  }

  .post-title {
    margin-top: 0;

    a {
      display: block;
      color: #333;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.50s ease 0s;

      &:hover {
        text-decoration: none;
        color: #1f80bb;
      }
    }
  }

  .post-info {
    list-style: none;
    padding: 10px 0 0 0;
    margin: 0 0 7px 0;
    text-align: center;
    border-top: 1px solid #d3d3d3;

    li {
      display: inline-block;
      margin-right: 13px;
    }
  }

  .tag-info {
    margin: 0;
    padding: 0 0 10px 0;
    text-align: center;
    border-bottom: 1px solid #d3d3d3;

    li {
      list-style: none;
      display: inline-block;

      a {
        color: #808080;
        text-transform: capitalize;

        &:hover {
          color: #1f80bb;
          text-decoration: none;
        }
      }
    }
  }

  .post-description {
    color: #828282;
    font-size: 14px;
    padding: 5px 25px;
    line-height: 25px;
  }

  .read-more {
    color: #333;
    float: right;
    font-weight: bold;
    margin-right: 25px;
    text-transform: capitalize;

    &:hover {
      color: #1f80bb;
      text-decoration: none;
    }
  }
}

.owl-theme .owl-buttons {
  div {
    position: relative;
    border-radius: 0;
    background: #807b87;
    padding: 7px 15px;
    transition: all 0.5s ease 0s;
  }

  .owl-prev {
    position: absolute;
    left: 0;
    top: 50%;
    opacity: 0;
    transition: all 0.50s linear;
  }
}

.owl-carousel:hover .owl-buttons .owl-prev {
  opacity: 1;
  left: -30px;
}

.owl-theme .owl-buttons .owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 0;
  transition: all 0.50s linear;
}

.owl-carousel:hover .owl-buttons .owl-next {
  opacity: 1;
  right: -30px;
}

.owl-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
}

.owl-next:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #fff;
  content: "\f054";
}

@media only screen and (max-width: 990px) {
  .post-slide9 .post-info li {
    margin-right: 5px;
  }

  .owl-theme .owl-buttons div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .post-slide9 {
    .post-link li a {
      width: 40px;
      height: 40px;
      line-height: 39px;
      font-size: 13px;
    }

    .post-title a {
      font-size: 14px;
    }
  }
}

/******************  News Slider Demo-10 *******************/

.post-slide10 {
  padding-bottom: 10px;
  margin: 0 15px;
  position: relative;
  background: #fff !important;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  img {
    width: 100%;
    height: auto;
  }

  .post-date {
    position: absolute;
    top: 2%;
    left: 8%;
    padding: 3% 5%;
    background: #e74c3c;
  }

  .month {
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      content: "";
      display: block;
      border: 1px solid #fff;
    }
  }

  .date {
    font-size: 14px;
    color: #fff;
    display: block;
    text-align: center;
    font-weight: bold;
  }

  .post-title {
    margin: 25px 0 15px 0;

    a {
      font-size: 15px;
      font-weight: bold;
      color: #333;
      padding: 0px 15px;
      display: inline-block;
      text-transform: uppercase;
      transition: all 0.3s ease 0s;

      &:hover {
        text-decoration: none;
        color: #e74c3c;
      }
    }
  }

  .post-description {
    font-size: 14px;
    line-height: 24px;
    color: #808080;
    padding: 0px 15px;
  }

  .read-more {
    color: #333;
    padding: 0px 15px;
    text-transform: capitalize;
    transition: color 0.20s linear;

    i {
      margin-left: 10px;
      font-size: 10px;
    }

    &:hover {
      text-decoration: none;
      color: #e74c3c;
    }
  }
}

.owl-controls {
  .owl-buttons {
    margin-top: 20px;
    position: relative;
  }

  .owl-prev {
    position: absolute;
    left: -40px;
    bottom: 230px;
    padding: 8px 17px;
    background: #333;
    transition: background 0.50s ease;
  }

  .owl-next {
    position: absolute;
    right: -40px;
    bottom: 230px;
    padding: 8px 17px;
    background: #333;
    transition: background 0.50s ease;
  }

  .owl-prev:after {
    content: "\f104";
    font-family: FontAwesome;
    color: #d3d3d3;
    font-size: 16px;
  }

  .owl-next:after {
    content: "\f104";
    font-family: FontAwesome;
    color: #d3d3d3;
    font-size: 16px;
    content: "\f105";
  }

  .owl-prev:hover, .owl-next:hover {
    background: #e74c3c;
  }
}

@media only screen and (max-width: 990px) {
  .post-slide10 {
    margin: 0 20px;
  }

  .owl-controls .owl-buttons {
    .owl-prev {
      left: -20px;
      padding: 5px 14px;
    }

    .owl-next {
      right: -20px;
      padding: 5px 14px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .owl-controls .owl-buttons {
    .owl-prev {
      left: 0px;
      bottom: 260px;
    }

    .owl-next {
      right: 0px;
      bottom: 260px;
    }
  }
}

/******************  News Slider Demo-11 *******************/

.post-slide11 {
  background: #fff;
  margin: 0 15px;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    position: relative;
  }

  .over-layer {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: all 0.30s ease 0s;
  }

  &:hover .over-layer {
    opacity: 1;
  }

  .over-layer:after {
    content: "+";
    font-size: 52px;
    color: #fff;
    position: absolute;
    top: 31%;
    left: 42%;
  }

  .post-img img {
    width: 100%;
    height: auto;
  }

  .post-title {
    margin: 25px 0 15px 0;
    padding: 0 15px;

    &:before {
      content: "";
      border: 2px solid #e67e22;
      width: 18%;
      display: block;
      margin-bottom: 15px;
    }

    a {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      display: inline-block;
      text-transform: capitalize;
      transition: all 0.3s ease 0s;

      &:hover {
        text-decoration: none;
        color: #e67e22;
      }
    }
  }

  .post-date {
    text-transform: capitalize;
    padding: 0 15px;
    color: #E67E22;
    font-size: 13px;

    &:before {
      margin-right: 7px;
      color: #e67e22;
    }
  }
}

.owl-theme .owl-controls {
  .owl-page.active span, &.clickable .owl-page:hover span {
    background: #e67e22;
  }
}

/******************  News Slider Demo-12 *******************/

.post-slide12 {
  background: #fff;
  margin: 0 15px;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
  margin-bottom: 2em;

  .post-img {
    position: relative;
    float: left;
    width: 50%;
    height: auto;
  }

  .over-layer {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.30s ease 0s;

    &:after {
      color: #fff;
      content: "+";
      font-size: 52px;
      position: absolute;
      top: 31%;
      left: 42%;
    }
  }

  .post-img {
    &:hover .over-layer {
      opacity: 1;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .post-review {
    float: left;
    padding: 1px 20px;
    width: 50%;
  }

  .post-title {
    margin: 0 0 5px 0;

    a {
      color: #3498db;
      font-size: 20px;
      font-weight: 700;
      display: block;
      text-transform: capitalize;
      transition: color 0.30s ease;
    }
  }
}

.post-title > a:hover {
  text-decoration: none;
  color: #333;
}

.post-slide12 .post-date {
  display: block;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 17px;
}

.post-description {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

.owl-pagination {
  margin-top: 40px;
}

.owl-theme .owl-controls {
  .owl-page.active span, &.clickable .owl-page:hover span {
    background: #3498db;
  }
}

@media only screen and (max-width: 990px) {
  .post-slide12 .over-layer:after {
    top: 39%;
    left: 45%;
  }
}

@media only screen and (max-width: 640px) {
  .post-slide12 {
    .post-img {
      width: 100%;
    }

    .post-review {
      width: 100%;
      padding: 10px;
    }
  }
}

/******************  News Slider Demo-13 *******************/

.post-slide13 {
  padding: 0 15px;

  .post-img {
    position: relative;

    > a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }

    &:hover:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(220, 0, 90, 0.6);
    }

    &:after {
      content: "\f065";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute;
      top: 17px;
      right: 20px;
      color: #fff;
      opacity: 0;
      transform: scale(0.8);
      transition: all 0.3s linear 0s;
    }

    &:hover:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  .post-title {
    margin-top: 20px;

    > a {
      color: #222;
      display: block;
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
        color: #dc005a;
      }
    }
  }

  .post-bar {
    padding: 0;
    list-style: none;

    > li {
      display: inline-block;
    }
  }

  .post-date {
    color: #8f8f8f;
    font-size: 12px;
    margin-right: 16px;
    text-transform: uppercase;
    font-style: italic;
  }

  .author {
    color: #8f8f8f;
    font-size: 12px;
    margin-right: 16px;
    text-transform: uppercase;
    font-style: italic;

    > a {
      color: #8f8f8f;
      font-size: 12px;
      margin-right: 16px;
      text-transform: uppercase;
      font-style: italic;
    }
  }

  .post-date > i {
    margin-right: 5px;
  }

  .author > {
    i {
      margin-right: 5px;
    }

    a:hover {
      color: #dc005a;
    }
  }

  .post-description {
    color: #8f8f8f;
    font-size: 14px;
    line-height: 24px;
    padding-top: 5px;

    &:before {
      content: "";
      display: block;
      border-top: 4px solid #dc005a;
      padding-bottom: 12px;
      width: 50px;
    }
  }
}

.owl-theme .owl-controls {
  .owl-page {
    span {
      width: 52px;
      height: 5px;
      border-radius: 0;
      opacity: 0.5;
      margin-bottom: 0;
    }

    &.active span {
      background: #dc005a;
      opacity: 1;
    }
  }

  &.clickable .owl-page:hover span {
    background: #dc005a;
    opacity: 1;
  }
}

/******************  News Slider Demo-14 *******************/

.post-slide14 {
  // border-width: 1px 1px 5px;
  // border-style: solid;
  // border-color: #e67e22 #f0f0f0 #f0f0f0;
  // border-radius: 5px;
  // margin: 0 10px;

  .post-category {
    border-bottom: 1px solid whitesmoke;
    margin: 0;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: capitalize;

    > a {
      text-transform: uppercase;
      color: #e67e22;
      transition: all 0.2s ease 0s;

      &:hover {
        color: #373a3f;
      }
    }
  }

  .post-review {
    overflow: hidden;
    padding: 10px;
  }

  .post-bar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $secondary-color;
    float: left;
    line-height: 34px;
    text-align: center;
    margin-right: 10px;

    > {
      .month {
        display: block;
        color: #fff;
        font-size: 10px;
        text-transform: capitalize;
      }

      .date {
        color: #fff;
        display: block;
        font-size: 28px;
        font-weight: bold;
        line-height: 5px;
        margin-top: 22px;
      }
    }
  }


  .post-title {
    line-height: 20px;
    margin: 10px 0 0 0;

    > a {
      font-size: 17px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 10px;
      color: #333;
      transition: all 0.2s ease 0s;

      &:hover {
        color: #e67e22;
      }
    }
  }

  .post-img {
    filter: grayscale(0);
    transition: all 0.3s ease 0s;

    > img {
      width: 100%;
      height: auto;
    }
  }

  &:hover .post-img {
    filter: grayscale(1);
  }

  .post-description {
    color: #555;
    font-size: 14px;
    line-height: 22px;
    padding: 20px 35px;
  }
}
