.home-page {
  article {
    padding-top: 0.2rem;
    padding-bottom: 0rem;
  }
  .circle-image {
    border-radius: 50%;
  }
  .dg-section {
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    transition: all 0.75s ease;

    .dg-img {
      height: 210px;
    }

    &:hover {
      .dg-img {
        transition: all 0.75s ease;
        background: #ddd;
        &:hover {
          background: $primary-color;
        }
      }
    }
  }

  .welcome-title {
    h3 {
      border-bottom: 0.9px solid #dedede;
      padding-bottom: 10px;
      font-size: 24px;
      color: $secondary-color;
      font-weight: 800;
      font-family: "Nunito Sans,sans-serif";
    }
    p {
      line-height: 1.5;
      color: gray;
    }
  }

  iframe {
    width: 100%;
    height: 492px;
    border: none;
  }

  .carousel,
  #carousel {
    padding-left: 0px !important;

    .carousel-item {
      height: 450px;
      transition: 15s ease-in-out left;
      // background-color: #fff;
      @include border-radius($card-inner-border-radius);
      // box-shadow: $box-shadow-hover;

      img {
        width: 100%;
        // height: 100%;
      }

      a {
        background: $primary-color;
        color: #000;
        padding: 0.5em;
        border-radius: 5px;
        &:hover {
          background: $primary-color !important;
          color: white;
          transition: all 0.5s ease;
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      // background-color: rgba(0,0,0,.5) !important;
    }

    .carousel-control-prev {
      margin-left: 20px !important;
    }

    .carousel-control-next {
      margin-right: 20px !important;
    }

    .carousel-control-prev,
    .carousel-control-next {
      position: absolute;
      top: 45%;
      bottom: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      color: #fff;
      text-align: center;
      opacity: 1;
      border-radius: 50%;
    }

    .carousel-caption,
    .mask {
      width: 100%;
      left: 0;
      bottom: 0;
      color: $primary-color !important;
      position: absolute;
      // padding: 0 20px;
      // background: rgba($primary-color,0.7);
      //background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), #212121 100%);
      padding: 1.5rem 1rem;
      // transition:

      h1 {
        font-size: 48px;
        color: $primary-color;
        font-weight: 800;
        font-family: "Nunito Sans,sans-serif";
        text-shadow: $primary-color;
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
      }

      a {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
      }

      img {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        display: block;
        height: 200px;
        max-width: 100%;
      }

      p {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        font-size: 1.6em;
        z-index: 22222;
        position: relative;
        font-weight: 900;
        //text-shadow: 1px 2px #333333;
      }
      .wave-svg {
        position: absolute;
        top: auto;
        bottom: 0;
        z-index: 10;
        pointer-events: none;
        width: 2000px;
        left: 50%;
        margin: 0 0 0 -1000px;
      }
    }

    h1,
    p,
    a,
    .carousel-item .mask img {
      -webkit-animation-duration: 1s;
      animation-duration: 1.2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }

  .meeting-nav {
    position: relative;
    bottom: 0;
    z-index: 999;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    top: -47px;

    .meeting-dots {
      padding: 11px 90px;
      margin: 0 auto;
      text-align: center;
      float: none;
      width: auto;
      display: inline-block;
      list-style: none;
      position: relative;
      background-color: #edeef0;
      top: -14px;
      border-top-left-radius: 51px;
      border-top-right-radius: 51px;

      &:before {
        content: " ";
        display: block;
        z-index: 99999;
        position: absolute;
        left: -30px;
        bottom: 0;
        top: 21px;
        border-top-left-radius: 0;
        width: 0;
        height: 0;
        border-bottom: 40px solid #edeef0;
        border-left: 40px solid transparent;
      }

      &:after {
        content: " ";
        display: block;
        z-index: 99999;
        position: absolute;
        right: -30px;
        bottom: 0;
        top: 21px;
        border-top-left-radius: 0;
        width: 0;
        height: 0;
        border-bottom: 40px solid #edeef0;
        border-right: 40px solid transparent;
      }

      li {
        display: inline-block;
        border: 2px solid transparent;
        border-radius: 14px;
        margin: 0;
        width: 30px;
        height: 40px;
        text-align: center;
        font-size: 16px;
        padding: 5px 0;
        font-weight: 500;
        color: #000;

        &.active {
          border: 2px solid #0e789d;
        }
      }
    }
  }

  .bg-colors {
    div {
      height: 8px;
    }
    .color-1 {
      background: $secondary-color;
    }
    .color-2 {
      background: $primary-color;
    }
    .color-3 {
      background: white;
    }
    .color-4 {
      background: $secondary-color;
    }
  }

  .highlights {
    margin-bottom: 1rem;
    border-bottom: 1px solid $secondary-color;
    padding-right: 3px;

    .skew {
      flex-shrink: 0 !important;
      position: relative;
      color: rgb(255, 255, 255);
      background: $primary-color;
      display: block;
      padding: 1.4rem 0.25rem 0 0.8rem;
      z-index: 2;
      font-size: 1.4em;

      &:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: $primary-color;
        transform-origin: top right;
        transform: skew(40deg, 0deg);
      }
    }

    a {
      span.text-black {
        color: black !important;
      }
    }
  }

  .nav-item {
    margin-top: -0.3rem;

    .nav-link {
      border-radius: 0;
      border: none;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 2rem;
      color: #495057;
      border-bottom: 0.3rem solid transparent;
      transition: all 0.3s ease;

      &.active {
        color: $primary-color;
        background-color: transparent;
        border-bottom: 0.3rem solid $primary-color;
      }
    }
  }

  .media {
    .media-body {
      .news-date {
        margin-right: 10px;
        color: #686868;
        font-weight: bold;

        .date-dec {
          font-size: 25px;
          line-height: 25px;
          padding-right: 3px;
          border-right: 2px solid #2d4a84;
        }

        .month-dec {
          font-size: 12;
          padding-right: 3px;
          border-right: 2px solid #2d4a84;
        }
      }
    }
  }

  .news img {
    max-width: 150px;
  }
}

.overlay-container {
  .overlay-inside {
    background: rgba($primary-color, 0.8);
  }

  .title-overlay {
    color: rgba(255, 255, 255, 0.9);
    font: 400 25px / 25px "Roboto-Bold";
    display: inline-block;
  }

  .full-screen-image {
    position: relative;
    height: auto;
    .overlay {
      background: url("/images/pattern.png") repeat !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #292929;
      opacity: 1;
    }
  }
}

.main-content {
  min-height: 350px;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    // background: url(../images/shadow_bg.png);
    z-index: -1;
    left: -40px;
    width: 77px;
    height: 332px;
    top: 0;
  }

  &::after {
    content: "";
    position: absolute;
    // background: url(../images/shadow_bg.png);
    z-index: -1;
    right: -25px;
    width: 77px;
    height: 332px;
    top: 0;
  }
  .main-content-inside {
    min-height: 350px;
    // background: white;
  }
}

.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s linear 0s;

  .thumbnail {
    background: #000000;
    //height: 200px;
    overflow: hidden;

    img {
      display: block;
      width: 120%;
      transition: all 0.3s linear 0s;
    }
  }

  .post-content {
    position: absolute;
    bottom: 0;
    background: #ffffff;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;

    .description {
      display: block !important;
      height: auto !important;
      opacity: 1 !important;
    }

    .category {
      position: absolute;
      top: -34px;
      left: 0;
      background: $secondary-color;
      padding: 10px 15px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .title {
      margin: 0;
      padding: 0 0 10px;
      color: #333333;
      font-size: 26px;
      font-weight: 700;
    }

    .sub_title {
      margin: 0;
    }

    .description {
      display: none;
      color: #666666;
      font-size: 14px;
      line-height: 1.8em;
    }

    .post-meta {
      margin: 30px 0 0;
      color: #999999;

      .timestamp {
        margin: 0 16px 0 0;
      }

      a {
        color: #999999;
        text-decoration: none;
      }
    }
  }

  &:hover,
  .hover {
    box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);

    .thumbnail img {
      transform: scale(1.1);
      opacity: 0.6;
    }
  }
}

.online-services {
  .service-item {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
    //width: 220px;
    height: 216px;
    overflow: hidden;
    text-align: center;
    padding-top: 40px;
    margin-right: 0;
    position: relative;
    transition: all 0.5s ease;

    .service-btn-wrapper {
      position: absolute;
      width: 100%;
      height: 40px;
      left: 0;
      bottom: -20%;
      opacity: 0;
      transition: all 0.5s ease;
      transition-delay: 0.15s;

      .serv-btn {
        text-align: center;
        color: #fff;
        font: normal 12px/35px Lato-Regular, DroidArabicKufi, sans-serif;
        border-radius: 35px;
        margin: 0 0.5%;
        height: 35px;
        background: $secondary-color;
        position: relative;
        display: inline-block;
        transition: all 0.3s ease;
        padding: 0 20px;
      }
      a:hover,
      a:focus {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: #ffffff;
      transform: scale(1.1);
      box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;

      .service-btn-wrapper {
        bottom: 15px;
        opacity: 1;
      }
    }
  }
}

.mazao {
  border: 1px #e1e1e1 solid;
  margin-top: 2px;
  min-height: 200px;
  background-blend-mode: difference;
  background: #fff
    url("https://cdn.pixabay.com/photo/2019/01/31/19/22/wheat-3967754__480.png")
    no-repeat center;

  .btn {
    text-align: center;
    color: #fff;
    border-radius: 35px;
    height: 35px;
    background: #d79e12;
    transition: all 0.3s ease;
  }
}

.ministry-expert {
  //border: 1px #e1e1e1 solid;
  margin-top: 2px;
  min-height: 200px;
  background-blend-mode: difference;
  //background: #fff url('https://cdn.pixabay.com/photo/2018/01/12/16/16/graph-3078545__480.png') no-repeat center;

  .card {
    display: block;
    background: #f4f6f5;
    border-radius: 4px;
    padding: 35px 15px;
    transition: all 0.5s ease;

    img {
      width: 17%;
    }

    &:hover {
      background: $primary-color;
    }
  }
}
.core-home-section {
  .core-activity {
    text-align: center;
    .div-link {
      display: block;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      transition: all 0.3s ease;
      color: #fefefe;

      .core-activity-inside {
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
        width: 100%;
        padding: 0;
        min-height: 300px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease;

        h3 {
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 200px;
          margin: auto;
          width: 100%;
          padding: 1rem;
          color: #fefefe;
          text-transform: uppercase;
          text-shadow: 2px 2px 2px #000;
          text-align: center;
        }

        button {
          width: 100%;
          color: #fefefe;
          background-color: $primary-color;
          padding: 0 1.5rem;
          transition: all 0.2s;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border: none;
        }
      }

      &:hover {
        color: white;

        .core-activity-inside {
          background-color: rgba(0, 0, 0, 0.6);
          button {
            transition: all 0.5s ease-in-out;
            background: $secondary-color-dark;
          }
        }
      }
    }
  }
}

.ega-announcement {
  .news-holder {
    background: #fff;
    padding: 15px 20px;
    margin-bottom: 15px;
    overflow: hidden;
  }

  .post-date {
    width: 100%;
    background: $primary-color;
    color: white;
    font-size: 0.9em;
    padding: 10px;
  }

  .news-title {
    color: #333;
  }

  .news .news-title {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

// Testimonial
.testimonial {
  .arrow {
    display: block;
    position: absolute;
    color: #ddd;
    cursor: pointer;
    font-size: 2em;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 5px;
    z-index: 2;

    &::before {
      cursor: pointer;
    }

    &:hover {
      color: $secondary-color;
    }

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }

  .dots {
    text-align: center;
    width: 100%;
    bottom: 60px;
    left: 0;
    display: block;
    height: 12px;

    .dot {
      list-style-type: none;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid $secondary-color;
      margin: 0 10px;
      cursor: pointer;
      -webkit-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      position: relative;

      .active,
      &:hover {
        background: $secondary-color;
        border-color: $secondary-color;
      }

      .active {
        -webkit-animation: testimonial-scale 0.5s ease-in-out forwards;
        -moz-animation: testimonial-scale 0.5s ease-in-out forwards;
        -ms-animation: testimonial-scale 0.5s ease-in-out forwards;
        -o-animation: testimonial-scale 0.5s ease-in-out forwards;
        animation: testimonial-scale 0.5s ease-in-out forwards;
      }
    }
  }

  .testimonial-content {
    overflow: hidden;

    div {
      text-align: center;
      top: 0;
      left: 0;
      padding: 0 0 30px 0;
      display: none;

      &.inactive {
        display: none;
      }

      &.active {
        position: relative;
        display: block;
      }

      .img img {
        display: block;
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
      }

      h2 {
        color: #333;
        font-size: 1em;
        margin: 15px 0;
      }

      p {
        font-size: 1.15em;
        color: #333;
        width: 80%;
        margin: auto;
      }

      &.active .img img {
        -webkit-animation: testimonial-show 0.5s ease-in-out forwards;
        -moz-animation: testimonial-show 0.5s ease-in-out forwards;
        -ms-animation: testimonial-show 0.5s ease-in-out forwards;
        -o-animation: testimonial-show 0.5s ease-in-out forwards;
        animation: testimonial-show 0.5s ease-in-out forwards;
      }

      &.active h2 {
        -webkit-animation: testimonial-content-in 0.4s ease-in-out forwards;
        -moz-animation: testimonial-content-in 0.4s ease-in-out forwards;
        -ms-animation: testimonial-content-in 0.4s ease-in-out forwards;
        -o-animation: testimonial-content-in 0.4s ease-in-out forwards;
        animation: testimonial-content-in 0.4s ease-in-out forwards;
      }

      &.active p {
        -webkit-animation: testimonial-content-in 0.5s ease-in-out forwards;
        -moz-animation: testimonial-content-in 0.5s ease-in-out forwards;
        -ms-animation: testimonial-content-in 0.5s ease-in-out forwards;
        -o-animation: testimonial-content-in 0.5s ease-in-out forwards;
        animation: testimonial-content-in 0.5s ease-in-out forwards;
      }

      &.inactive .img img {
        -webkit-animation: testim-hide 0.5s ease-in-out forwards;
        -moz-animation: testim-hide 0.5s ease-in-out forwards;
        -ms-animation: testim-hide 0.5s ease-in-out forwards;
        -o-animation: testim-hide 0.5s ease-in-out forwards;
        animation: testim-hide 0.5s ease-in-out forwards;
      }

      &.inactive h2 {
        -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
        -moz-animation: testim-content-out 0.4s ease-in-out forwards;
        -ms-animation: testim-content-out 0.4s ease-in-out forwards;
        -o-animation: testim-content-out 0.4s ease-in-out forwards;
        animation: testim-content-out 0.4s ease-in-out forwards;
      }

      &.inactive p {
        -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
        -moz-animation: testim-content-out 0.5s ease-in-out forwards;
        -ms-animation: testim-content-out 0.5s ease-in-out forwards;
        -o-animation: testim-content-out 0.5s ease-in-out forwards;
        animation: testim-content-out 0.5s ease-in-out forwards;
      }

      @keyframes testimonial-scale {
        0% {
          box-shadow: 0px 0px 0px 0px #eee;
        }

        35% {
          box-shadow: 0px 0px 10px 5px #eee;
        }

        70% {
          box-shadow: 0px 0px 10px 5px #ea830e;
        }

        100% {
          box-shadow: 0px 0px 0px 0px #ea830e;
        }
      }

      @keyframes testimonial-content-in {
        from {
          opacity: 0;
          transform: translateY(100%);
        }

        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes testim-content-out {
        from {
          opacity: 1;
          transform: translateY(0);
        }

        to {
          opacity: 0;
          transform: translateY(-100%);
        }
      }

      @keyframes testimonial-show {
        from {
          opacity: 0;
          transform: scale(0);
        }

        to {
          opacity: 1;
          transform: scale(1);
        }
      }

      @keyframes testim-hide {
        from {
          opacity: 1;
          transform: scale(1);
        }

        to {
          opacity: 0;
          transform: scale(0);
        }
      }

      @media all and (max-width: 500px) {
        .testimonial .arrow {
          font-size: 1.5em;
        }
      }
    }
  }
}

.services-inner {
  border: 1.5px solid $primary-color;
  // margin-left: 35px;
  transition: 0.3s;

  .our-services-img {
    float: left;
    margin-left: -36px;
    margin-right: 22px;
    margin-top: 28px;
  }
  .our-services-text {
    padding-right: 10px;
    overflow: hidden;
    padding: 28px 0 25px;

    h4 {
      color: #222222;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 8px;
      padding-bottom: 10px;
      position: relative;
      text-transform: uppercase;

      &:before {
        background: #ec6d48 none repeat scroll 0 0;
        bottom: 0;
        content: "";
        height: 1px;
        position: absolute;
        width: 35px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }

  .our-services-wrapper:hover .services-inner {
    background: #fff none repeat scroll 0 0;
    border: 2px solid transparent;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #666;
    margin-bottom: 15px;
  }
}

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: rgba(75, 75, 75, 0.7);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hovereffect:hover .overlay {
  background-color: rgba(48, 152, 157, 0.4);
}

.hovereffect img {
  display: block;
  position: relative;
}

.hovereffect h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hovereffect:hover h2 {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}

.hovereffect a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  font-weight: normal;
  margin: -52px 0 0 0;
  padding: 62px 100px;
}

.hovereffect:hover a.info {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.hovereffect a.info:hover {
  box-shadow: 0 0 5px #fff;
}

// cards
.details-card {
  background: #ecf0f1;
}

.service-single {
  margin-top: 30px;
  padding-bottom: 10px;
  transition: all 0.5s ease-in;

  //border: 1px solid #e8e8e8;
  //box-shadow: 0 0 10px #e8e8e8;
  .box-top {
    //background: $secondary-color;
    //padding: 30px;
    color: #fff;
    border-top-right-radius: 10%;
    border-top-left-radius: 10%;
    transition: all 0.5s ease-in;

    h2 {
      font-size: 16px;
      margin-bottom: 45px;
    }
  }

  .box-down {
    //max-width: 350px;
    margin: 0 auto;
    border-radius: 10%;
    background: #fff;
    //margin-top: -130px;
    //padding: 50px 25px 10px;
  }

  .btn-holly {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    color: #ffa500;
    transition: all 0.1s ease-in;
  }

  &:nth-child(2n + 1) {
    .box-top {
      background: rgba($primary-color, 0.9);
    }
  }

  &:nth-child(2n) {
    .box-top {
      background: rgba($yellow, 0.9);
    }
  }

  &:hover {
    .btn-holly {
      background: rgba($green, 0.9);
      border-color: rgba($green, 0.9);
      color: #fff;
    }

    &:nth-child(1n) {
      .box-top {
        background: rgba($green, 0.9);
      }
    }

    &:nth-child(2n) {
      .box-top {
        background: rgba($green, 0.9);
      }
    }
  }
}

.card-content {
  background: #333;
  border: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

  .card-img {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    a {
      position: absolute;
      top: 15%;
      left: 12%;
      background: #1abc9c;
      padding: 6px;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      transform: translate(-50%, -50%);
    }
  }

  .btn-card {
    background-color: $secondary-color;
    color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 0.84rem 2.14rem;
    font-size: 0.81rem;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    margin: 0;
    border: 0;
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
  }
  .btn-card:hover {
    background: $primary-color;
  }
  a.btn-card {
    text-decoration: none;
    color: #fff;
  }
  .card-desc {
    padding: 1.25rem;

    h3 {
      color: #000000;
      font-weight: 600;
      font-size: 1.1em;
      line-height: 1.3em;
      margin-top: 0;
      margin-bottom: 5px;
      padding: 0;
    }
    p {
      color: #747373;
      font-size: 14px;
      font-weight: 400;
      font-size: 1em;
      line-height: 1.5;
      margin: 0px;
      margin-bottom: 20px;
      padding: 0;
      font-family: "Raleway", sans-serif;
    }
  }
}

.video-wrapper {
  position: relative;

  /* Video Play Button */
  .video-play-button {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    display: block;
    box-sizing: content-box;
    width: 2rem;
    height: 2.75rem;
    padding: 1.125rem 1.25rem 1.125rem 1.75rem;
    border-radius: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    display: block;
    width: 4.75rem;
    height: 2.75rem;
    border-radius: 15%;
    background: $secondary-color;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  &:hover {
    .video-play-button:before {
      height: 4.75rem;
      border-radius: 50%;
      opacity: 0.8;
      transition: all 0.5s ease;
    }
  }
  // .video-play-button:after {
  //   content: "";
  //   position: absolute;
  //   z-index: 1;
  //   top: 50%;
  //   left: 50%;
  //   display: block;
  //   width: 4.375rem;
  //   height: 4.375rem;
  //   border-radius: 50%;
  //   background: $secondary-color;
  //   transition: all 200ms;
  //   -webkit-transform: translateX(-50%) translateY(-50%);
  //   transform: translateX(-50%) translateY(-50%);
  // }

  .video-play-button span {
    position: relative;
    display: block;
    z-index: 3;
    top: 0.375rem;
    left: 0.25rem;
    width: 0;
    height: 0;
    border-left: 1.625rem solid #fff;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
  }
}

.ega-btn-line {
  border-bottom: 2px solid $primary-color;
  transition: all 0.5s ease;

  .bg-yellow {
    background: rgba($yellow, 0.9);
    color: white;
  }

  .bottom-line {
    .badge {
      transition: all 0.5s ease;
      background: $secondary-color;
    }
  }

  &:hover {
    border-bottom: 2px solid $secondary-color;
  }
}

.img-frame {
  background-color: #fff;
  border: solid 5vmin #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  margin: 2.5vh 1vw;
  // max-height:400px;
  padding: 1vmin;
  position: relative;
  text-align: center;
  width: 85%;
  left: 3.5%;

  &:before {
    border-radius: 2px;
    bottom: -2vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
    content: "";
    left: -2vmin;
    position: absolute;
    right: -2vmin;
    top: -2vmin;
  }
  &:after {
    border-radius: 2px;
    bottom: -2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    content: "";
    left: -2.5vmin;
    position: absolute;
    right: -2.5vmin;
    top: -2.5vmin;
  }

  img {
    // border:solid 2px;
    // border-bottom-color:#ffe;
    // border-left-color:#eed;
    // border-right-color:#eed;
    // border-top-color:#ccb;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
}

.how-do-i {
  &:nth-child(1n) {
    background: rgba($yellow, 0.9);

    a {
      color: #fff;
    }
  }

  &:nth-child(2n) {
    background: $primary-color;

    a {
      color: #fff;
    }
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@media (min-width: 1920px) {
  .ziara {
    .bottom-line {
      bottom: -140px;

      .badge {
        // display: none;
      }
    }
  }
  .h-xl-480 {
    height: 480px;
  }
}

@media (max-width: 1024px) and (min-width: 992px) {
  .ziara {
    .bottom-line {
      bottom: -15px;

      .badge {
        display: none;
      }
    }
  }
}

@media (max-width: 576px) {
  .home-page {
    .carousel {
      .carousel-item {
        // height: 300px;

        // img {
        //   height: 100%;
        // }
      }
    }

    .dg-section {
      .dg-img {
        height: 100px;
      }
    }

    .circle-image {
      width: 100px !important;
      height: 100px;
      float: left;
    }
    .welcome-title {
      padding-left: 35px !important;
      padding-right: 35px !important;
      text-align: justify;
      h3 {
        text-align: center;
      }
    }
    .news {
      img {
        // display: none;
        max-width: 100%;
      }
    }
    .ega-heading-2 {
      text-align: center;
    }
  }

  .service-single {
    .box-top {
      border-radius: 0px;
    }
  }
}

@media (max-width: 992px) {
  .home-page {
    .carousel {
      .carousel-item {
        // height: 300px;

        // img {
        //   height: 100%;
        // }
      }

      .carousel-caption {
        // bottom: 100px;
      }
    }

    .dg-section {
      .dg-img {
        height: 100px;
      }
    }
    .news-tab {
      .nav {
        .nav-item {
          &::after {
            display: none;
          }
        }
      }
    }

    .circle-image {
      width: 100px !important;
      height: 100px;
      float: left;
      left: 6%;
      position: relative;
    }
    .welcome-title {
      padding-left: 35px !important;
      padding-right: 35px !important;
      text-align: justify;
      h3 {
        text-align: center;
      }
    }
    .news {
      img {
        // display: none;
      }
    }
    .ega-heading-2 {
      text-align: center;
    }

    .testimonial {
      .arrow {
        font-size: 2em;
        padding: 2px;
      }
      .dots {
        padding-left: 0px;
      }
      .testimonial-content {
        max-width: 80% !important;
        padding: 0px;
        margin-left: 10%;
      }
    }
  }
}
.skew-right-side {
  transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
}

#carouselSection {
  height: 450px;


}
.highlights-card {
  //margin-top: -50px;
  margin-top: 0px;
  .row {
    .col-md-4 {
      .fa {
        font-size: $text-xl;
        color: $secondary-color !important;
      }
    }
  }
}
.welcome-card-height {
  min-height: 450px;
}
.welcome-card {
  min-height: 450px;
  .row {
    .img-section {
      cursor: pointer;
      .welcome-card-img-caption {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba($primary-color, 0.7);
        height: 75px;
        color: #ffffff;
        p,
        strong {
          font-size: $text-sm;
        }
      }
    }
    .welcome-card-text-section {
      position: relative;

      .welcome-card-text-container {
        background-color: rgba($primary-color, 0.9);
        position: absolute;
        color: #ffffff;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        h4 {
          font-size: $text-lg;
        }
        p {
          font-size: $text-md;
        }
      }
    }
  }
}
.video-news-section {
  min-width: 350px;
}
.custom-section-title {
  font-size: $text-md;
  font-weight: bold;
  border-bottom: 3px solid $secondary-color;
  color: $primary-color;
}
.carousel-image-height {
  height: 450px;
}
.event-date-border {
  border-bottom: 2px solid $secondary-color;
}
@media (max-width: 576px) {
  .home-page {
     padding: 0.5rem !important;
  }
  .carousel-image-height {
    height: 300px !important;
  }
 .welcome-card, .welcome-card-height {
    min-height: 300px !important;
  }
  
}


.sponsor-image-holder{
    height:100px;
    min-width: 90px;
  //  background-color: rgba($primary-color, 1); //preview

    img{
      height: 100%;
      width:auto;
      filter: grayscale(100%);
      transition: filter 0.5s;

      &:hover{
        filter:grayscale(0%);
      }
    }
}

.sponsor-text{

}

.marquee-div{
  width:200%;

  .li {
    float: left;
    width: 100%;
    //padding: 15px;
    //margin: 1% 10%;
    //height: 20px;
    //border-radius: 0.5em;
    //box-shadow: 0 0.1em 0.5em;
  }

  .rightCSS:hover a {
    animation-play-state: paused;
  }

  .rightCSS { 
    background-color: darken($primary-color-dark, 10%);
    overflow: hidden;
  }
  
  .rightCSS a {
    color: $secondary-color;
    font-weight: 600;
    padding: 8px;
    position: relative;
    animation: CSSright linear 18s infinite;
    z-index: 999;
    text-transform: uppercase !important;

    &:hover{
      color: $white;
    }
  } 
  @keyframes CSSright {
    0% { right: -50% }
    100% { right: 100% }
  }
}


.bold-line{
  height:3px;
  background-color: $primary-color;
}

.thin-line{
  height: 1px;
  background-color:rgba(gray,0.5)

}

  
.gray-border{
  border-color:rgba(gray,0.2) !important;
}





.primary-border{
  border:2px solid rgba($primary-color, 0.2);
  transition: border 0.3s;

  i{
    position:relative;
    transition: left 0.4s;
    left:0px;
  }

  &:hover{
    border:2px solid rgba($secondary-color, 0.5);

    i{
      left:10px;
    }
  }
}

.action-link{
   
}

.text-faded{
  opacity:0.6;
}

.news-card{
  border-bottom:2px solid $primary-color !important;
}

.card-image-full{
  width:100%;
  background-color: #222222;
  background-position: center;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    padding-bottom: 56.25%;
  }
}

// new TSC

.bg-image{
  background-size:cover; 
  background-position: center;
}

.bg-image-height{
  background-size:auto 100%; 
  background-position: center;
  background-repeat:no-repeat;
  overflow:hidden !important;
}

.board-member{
  width: 100%;
 // background-color:$primary-color;
  border-bottom: 3px solid $secondary-color;
  background-size:auto 100%; 
  background-position: center;
  background-repeat:no-repeat;
  overflow:hidden !important;
  //border-radius: 5px 5px 0px 0px;
  position: relative;
  
  img{
    height:100%;
    width: auto;
    position: absolute;
    border: 2px solid $primary-color;
    border-radius: 5px;
  }


  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.landing-section {

  .slider{
    background-color: $primary-color;
    overflow: hidden;
    position: relative;
    padding-bottom:50% ;
    width:100%;

    .carousel{
      position: absolute !important;
      height:100%;
      top:0;left: 0;
      width: 100%;
     
    }

    .carousel-inner{
      height:100%;
    }

    .carousel-item{
      height:100%;
    }

  }

  .slider-inner-container{
    position:absolute;
    width:100%;
    height:100%;
    top:0; left:0;
  }

  .line{
    top:0;left:0;right:0;bottom:0;
    margin:auto;
  }

  .board-vertical-line{
    height:25%;
    width:3px;
    background-color: $primary-color;
  }

  .board-horizontal-line{
    height:3px;
    width:12%;
    background-color: $primary-color;
  }
}

.news-section{
  //background-color: $primary-color;
  min-height: 200px;
}

.section-head{
  padding-bottom: 1em;
  border-bottom: 2px solid $secondary-color;
  color:rgba(black,0.5);
  
  .line {
    
    width:30%;
    height:3px;
   // margin:0 auto;
    position: relative;
    top:1em;
    transition: width 0.3s;
    
    background-color: $primary-color;

  }

  


}

.section-column {

  &:hover .line{
    width:100%;
  }
}

.page-section {
  a {
    font-size:1em !important;
  }

  .nav-item{
   
    .nav-link{
      border-top:3px solid $white;
    }

    .active {
      border-top:3px solid $primary-color;
    }


  }
}



.flip-card {
  //background-color: transparent;
  position:absolute;
  top:0;left:0;
  width: 100%;
  height:100%;

  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}


.home-slider{
  z-index: 1;
 // position: relative;

  @include media-breakpoint-up(lg) { 
  //  margin-top:-200px;
  }
}



.carousel-inner{
  position: relative;

  .full-hd-container::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(#333, 0.35);
  }
 
}


.slider-nav-controls{
    top:0;
    bottom: 0;
    margin:auto;
    height:fit-content !important;
    // background-color: pink;
    left:0%;
    z-index: 98;

    .nav-control{
      border-color:white;
      border-style:solid;
      // background-color:rgba($primary-color, 0);
      transition: background 0.3s;

      &:hover{
        // background-color:rgba($primary-color, 1);
      }
    }
    
  .nav-control:nth-child(1){
    border-width:2px;
    border-radius:50%;
  }

  .nav-control:nth-child(2){
    border-width:2px;
    border-radius:50%;
    
  }

}

.slider-nav-arrows{
  color:white !important;
}

.carousel-text{
  position:absolute;
  z-index: 96;
  bottom:0px;

  // top:200px;
  // left:10px;

}

.home-popup{
  z-index: 97;
  right:0px; 
  transition:transform 0.4s, opacity 0.4s;
  transform:scale(1);
  opacity: 1;

  .popup-text{
    position:relative;
    transition: background-color 0.4s,top 0.3s;
    background-color: rgba($primary-color, 0.7);
    top:0px;

    &:hover{
      background-color: rgba($primary-color, 0.9);
      top:-7px;
    }
  }

  div { border-radius: 10px !important; }

  .popup-close{
    position: absolute;
    top:10px;
    right:30px;
    opacity:0.5;
    transition:opacity 0.3s;

    &:hover{
      opacity: 1;

    }

  }
}

.hover-investor{
  
  .investor-name{
    transition: color 0.4s !important;
    color:rgba($secondary-color, 0.9)
  }
}

.social-media-side{
  position: fixed;
  right:15px;
  top:220px;
  z-index: 2;

  .social-media-box{
    margin-top: 1px;

    .social-text{
      transition:max-width 0.25s;
      transition:padding 0.3s;
      max-width: 0px;
      padding:0 0px !important;
      overflow: hidden;
    }

    &:hover .social-text {
      max-width:150px ;
      padding:0 16px !important;
    }
  }
}

.scroll-to-content {
  position: absolute;
  left:0px; right:0px;
  margin:auto;
  z-index:997;
  display: inline-block;
  width:fit-content;
  text-align: center;
  width:56px;
  height:56px;
  bottom:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  // border:2px solid white;

  transition: background 0.3s,bottom 0.3s;
  //background-color: rgba($primary-color, 0);

  .fa { 
    position:relative;
    transition:top 0.3s;
    top:0px
  }

  &:hover{
  //  background-color: rgba($primary-color, 0.7);
    bottom:-5px;
    .fa{top:5px}
  }

}

.scroll-to-top{
  position: relative;
  left:0px; right:0px;
  margin:auto;
  z-index:997;
  width:fit-content;
  text-align: center;
  width:56px;
  height:56px;
  margin-top:-2em;
  top:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border:2px solid rgba($primary-color, 1);

  transition: background 0.3s,bottom 0.3s;
  background-color: rgba($primary-color, 1);

  .fa { 
    position:relative;
    transition:top 0.3s;
    top:0px
  }

  &:hover{
   // background-color: rgba($primary-color, 0.7);
   // top:-8px;
    .fa{top:-5px}
  }

}

.home-popup.closed{
  transform:scale(0.6);
  opacity: 0;
}

.home-content{
  position: relative;
  z-index: 1;

  //added background-color gradient...
  .home-container{
    // @include media-breakpoint-up(lg){ margin-top:-50px ; }
    // z-index: 1;
    // min-height: 200px;
  }
}

.home-page-body{
  position: relative;
  background: white;
  @include media-breakpoint-up(lg){ margin-top:-60px; }



  
}

.guides{
//  overflow: hidden;
  position: relative;
  &::before{
    content:"";
    display: block;
    position:absolute;
    top:0%;left:0;
    height: 80%;
    width:100%;
    z-index: 0;
    background: rgba(white, 1);
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }


}

.events{
  position: relative;

  &::before{
    content:"";
    z-index: 0;
    display: block;
    position:absolute;
    top:0%;left:0;
    height: 100%;
    width:100%;
    background: rgba(#f8f8f8, 1);
    clip-path: polygon(100% 0, 100% 40%, 80% 100%, 0 100%, 0 0);
  }
}



///owl


.owl-item{
  width:auto;
  height: fit-content;
}

  


.custom-owl-nav,.custom-owl-nav-2,.custom-owl-nav-3{
  top:-50%; 
  transform:translateY(-50%); 
  bottom:0; 
  margin:auto; 
  left:0; 
  height: 0;

  .owl-prev, .owl-next {
    position: absolute;
    transition: 0.5s;
    border:unset !important;
    background-color: unset !important;
    opacity: 0.7;
    &::before,&::after{
      content:"" !important;
      opacity: 0;
      display: none !important;
    }
  }

  .owl-prev{
    left: -15px;
  }
  .owl-next{
    right: -15px;
  }
  .owl-prev:hover , .owl-next:hover{
    background: transparent !important; 
    opacity: 0.9;
  }
  
}

.bg-sm-white{
  @media (max-width: 768px) {
    background-color: white !important;
  }
}

.owl-dots{
  position: absolute;
  bottom: 0px;
  left:50%;
  transform: translateX(-50%);
  // background: #000;
  padding: 5px 5px 2px 5px; 
  // border-radius: 20px;
  opacity: 0.7;

  .owl-dot{
    background-color:unset !important;
    border:unset !important;
    width:15px;
   

    span{
      border-radius: 0 !important;
    
    }
  }

  .owl-dot:hover span{
    background: rgba($primary-color, 1) !important;
  }

  .owl-dot.active span{
    background: rgba($secondary-color, 1) !important;
  }
   
}




//swiper
.swiper-cards .swiper-slide {
  overflow: visible !important;
}

.swiper-3d .swiper-slide-shadow {
  background: rgba(129, 129, 129, 0.1) !important; 
}

.swiper-indicator{
  display: inline-block;
  width:10px;
  height:10px;
  background-color: rgba($primary-color, 0.5);
  transition: background-color 0.4s;

  &:hover{background-color: rgba($primary-color, 0.9) ;}
  &.active{ background-color: rgba($secondary-color, 0.9) ;}
}

.swiper-navigations{
  height:0px; z-index:99; left:0; top:0; bottom:0; margin:auto
}

.swiper-slide {
  background: #fff;
  position: relative;
  z-index:1;
  border-top:3px solid rgba($secondary-color, 0);

  .swiper-description{transition:opacity 0.5s;opacity: 0.1; }
  
  &.swiper-slide-active{
    border-top:3px solid rgba($secondary-color, 1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .swiper-description{transition:opacity 0.5s;opacity: 0.75; }
    .img {  border:5px solid rgba($secondary-color, 1);}
  }
 
  .img {
    transition:all 0.9s;
    transition-delay: 0.4s;
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 150px;
    height: 150px;
    border:5px solid #fdfdfd;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  
 
 
}
