@font-face {
    font-family: 'BrushScriptMT';
    src: url(../fonts/brushscriptmt.ttf);
}

@font-face {
    font-family: 'Diplomata';
    src: url(../fonts/Diplomata/Diplomata-regular.ttf);
}

@font-face {
    font-family: 'Josefin Sans';
    src: url(../fonts/Josefin_Sans/JosefinSans-Regular.ttf);
}

@font-face {
    font-family: 'Futura';
    src: url(../fonts/Futura/Futura-Regular.otf);
    font-weight: 400;
}

@font-face {
    font-family: 'Futura Bold';
    src: url(../fonts/Futura/Futura-Bold.otf);
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(../fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(../fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
    font-family: 'Roboto-Black';
    src: url(../fonts/Roboto/Roboto-Black.ttf);
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(../fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url(../fonts/Roboto/Roboto-Thin.ttf);
}
$text-base-size: 1em;
$text-scale-ratio: 1.2;
$text-xxs: 1em / ($text-scale-ratio * 3);
$text-xs: 1em / ($text-scale-ratio * 2);
$text-sm: 1em / $text-scale-ratio;
$text-md: 1em * $text-scale-ratio;
$text-lg: 1em * $text-scale-ratio * 2;
$text-xl: 1em * $text-scale-ratio * 3;
$text-xxl: 1em * $text-scale-ratio * 4;
$text-xxxl: 1em * $text-scale-ratio * 5;
.text-xs {
    font-size: $text-xs !important;
  }
  .text-sm {
    font-size: $text-sm !important;
  }
  .text-md {
    font-size: $text-md !important;
  }
  .text-lg {
    font-size: $text-lg !important;
  }
  .text-xl {
    font-size: $text-xl !important;
  }