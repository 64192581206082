/*
* ----------------------------------------------------------------
* e-Government Authority (eGA) - minified CSS File
* ----------------------------------------------------------------
*/


// COMPONENTS!!
@import "./node_modules/bootstrap/scss/bootstrap";
//@import "./node_modules/datatables/media/css/jquery.dataTables.min";
@import "base/fontawesome";
@import "../tmp/components/fontawesome/scss/brands";
@import "../tmp/components/fontawesome/scss/regular";
@import "../tmp/components/fontawesome/scss/solid";
@import "../tmp/components/slick/scss/slick";
@import "../tmp/components/animate/css/animations";
@import "../tmp/components/owl/owl.carousel.min";
@import "../tmp/components/owl/owl.theme.default.min";

// BASE partials
@import "base/variables";
@import "base/base";
@import "base/cover";
@import "base/shadow";
@import "base/fonts";
@import "base/slick-theme";
@import "base/magnific-popup";

// Header, Sidebar & Footer
@import "partials/header";
@import "partials/dropdown";
@import "partials/sidebar";
@import "partials/footer";
@import "partials/slider";
@import "partials/chatbot";

@import "base/sticky_social_media";

// PAGES partials here
@import "base/post";
@import "base/timeline";
@import "pages/contact";
@import "pages/index";
@import "pages/boilers";
@import "pages/all-page-content";
@import "pages/side_bread";
@import "pages/profile";
